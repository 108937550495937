import React, { useEffect, useState } from "react";
import DynamicTableInfinite from "../widgets/dynamicTableInfinite/DynamicTableInfinite";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import HelperMetiers360 from "../../services/HelpersMetiers360";

/**
 * 
 * @param {Array} romes array of all romes to be rendered
 * @param {String} readAll  readAll slug to access the rome list page
 * @param {String} readOne  readOne slug to access a rome details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the rome creation page - optional, need according rights
 * @param {String} update  update slug to access the rome update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 
 * @example
 *  <MetiersTableComponent romes={romes} readAll={readAllSlug} create={createSlug} {...}/>
 * 
 */

const RomesTableComponent = ({ romes = [], withParams }) => {
    const [romeToDisplay, setRomeToDisplay] = useState([]);
    const [sortedRome, setSortedRome] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    const romesSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('romes'));
    const navigate = useNavigate();

    useEffect(() => {
        setShouldSort(true);
        setSortedRome([...romes]);
    }, [romes]);

    const { hasMore, fetchNext: fetchData } = useInfiniteLoadingLogic(sortedRome, romeToDisplay, setRomeToDisplay, 100);

    if (romes.length === 0) {
        return <></>;
    }

    const sortType = [
        {
            value: 'label', label: 'Nom',
            test: romes[0].label !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label),
            display: (rome) => rome.label
        },
        {
            value: 'codeRome', label: 'Code ROME',
            test: romes[0].codeRome !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.codeRome, b.codeRome),
            display: (rome) => rome.codeRome
        },
        {
            value: 'usage', label: 'Utilisation',
            test: romes[0].usage !== undefined,
            method: (a, b) => b.usage - a.usage,
            display: (rome) => rome.usage
        },
        {
            value: 'usageVideos', label: 'Expériences immersives',
            test: romes[0].usageVideos !== undefined,
            method: (a, b) => b.usageVideos - a.usageVideos,
            display: (rome) => rome.usageVideos
        },
        {
            value: 'usageStories', label: 'Stories',
            test: romes[0].usageStories !== undefined,
            method: (a, b) => b.usageStories - a.usageStories,
            display: (rome) => rome.usageStories
        },
        {
            value: 'riasecMajeur', label: 'Riasec majeur',
            test: romes[0].riasecMajeur !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(
                HelperMetiers360.riasecLabel(a.riasecMajeur),
                HelperMetiers360.riasecLabel(b.riasecMajeur)
            ),
            display: (rome) => {
                return HelperMetiers360.riasecLabel(rome.riasecMajeur);
            }
        },
        {
            value: 'riasecMineur', label: 'Riasec mineur',
            test: romes[0].riasecMineur !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(
                HelperMetiers360.riasecLabel(a.riasecMineur), 
                HelperMetiers360.riasecLabel(b.riasecMineur)
            ),
            display: (rome) => {
                return HelperMetiers360.riasecLabel(rome.riasecMineur);
            }
        },
    ];

    const handleClick = (codeRome) => {
        if(romesSlugs.readOne) {
            navigate(romesSlugs.readOne.replace(':uniqueId', codeRome));
        }
    }

    return romeToDisplay.length > 0
        && <DynamicTableInfinite
            contentTable={romeToDisplay}
            contentSort={sortType}
            valueInitSort="codeRome"
            index='codeRome'
            fetchData={fetchData}
            hasMore={hasMore}
            setSortedContent={setSortedRome}
            sortedContent={sortedRome}
            sortState={[shouldSort, setShouldSort]}
            withParams={withParams}
        handleClick={handleClick}
            tableName="romesTable"
            filename="codes_romes" />;
}

export default RomesTableComponent;