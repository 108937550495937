import React, {useState, useEffect} from "react";
import { Container, Row, Col, ToggleButton, ToggleButtonGroup, Button, Alert} from "react-bootstrap";
import { Link } from "react-router-dom";

import { useStoreState, useStoreActions } from 'easy-peasy';

import SequenceTableComponent from "../../components/sequences/sequenceList/sequenceTable/SequenceTableComponent.jsx";
import SequenceBoardComponent from "../../components/sequences/sequenceList/sequenceBoard/SequenceBoardComponent.jsx";

import Loader from "../../components/widgets/Loader.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";

import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";


import "./SequencesPage.scss";

const SequenceTableWithParams = withURLParamsTableComponent(SequenceTableComponent);


const SequencesPage = () => {

    const { allSequences, isFetchingSequences } = useCustomGetStoreState("sequences")

    const sequenceSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("sequences"));

    // preferences from the store that is hydrated from local storage
    const viewStylePreference = useStoreState(state => state.preferences.sequenceListStyle);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setSequenceListStyle);

    const sequenceBoardSortType = useStoreState(state => state.preferences.sequenceBoardSortType);
    const setSequenceBoardSortType = useStoreActions(actions => actions.preferences.setSequenceBoardSortType);

    const [filteredSequences, setFilteredSequences] = useState(allSequences);

    const viewStyleType=[
        {value : 'table', label:<><i className="fas fa-table"></i></>, view : <SequenceTableWithParams sequences = {filteredSequences} {...sequenceSlugs} />},
        {value : 'cards', label:<><i className="fas fa-address-card"></i></>, view : <SequenceBoardComponent sequences = {filteredSequences}  sortTypePreferenceTuple={[sequenceBoardSortType, setSequenceBoardSortType]} {...sequenceSlugs}/>},
    ];

    const [viewStyle, setViewStyle] = useState(viewStylePreference);

    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value === viewStyle}>{type.label}</ToggleButton>
    );
    
    const {searchInput, search} = useSearchWithParams({
        placeholder: 'Rechercher par mots-clés (nom, description, producteur)', delay: 400, width: "35vw"
    })

    useEffect(() => {
        setFilteredSequences(allSequences)
	}, [allSequences]);

    useEffect(() => {
        setFilteredSequences(
            [...allSequences.filter(
                sequence => HelperMetiers360.isSearchInText(search, sequence.name)
                    || HelperMetiers360.isSearchInText(search, sequence.description)
                    || HelperMetiers360.isSearchInText(search, sequence.producers?.map(producer => producer.clientName)?.join())
                )]
        );
	}, [search, allSequences]);

	

    const createSequenceButton = sequenceSlugs.create ?
    <Button variant="success" as={Link} to={sequenceSlugs.create}><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>
    : null;

    const isNoSequence = !isFetchingSequences && allSequences.length === 0;
    const isNoResult = !isFetchingSequences && allSequences.length > 0 && filteredSequences.length === 0;

    return <>
        <Container fluid>
            <Row className="item-page">
                <Col className="m360-col3-start">
                    {searchInput}
                </Col>
                <Col className="m360-col3-center">
                    <ToggleButtonGroup type="radio" name="options" value={viewStyle} onChange={handleChange}>
                        {checkViewStyle}
                    </ToggleButtonGroup>
                </Col>
                <Col  className="m360-col3-end">
                    {createSequenceButton}
                </Col>
            </Row>
            <Row>
                { filteredSequences.length > 0 && viewStyleType.filter(type => type.value===viewStyle)[0].view }
                {isNoResult && <Alert className="mt-3" variant="warning"> Aucun parcours ne correspond à votre recherche</Alert>}
                {isNoSequence && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun parcours</Alert>}

                { (isFetchingSequences) && <Loader /> }
            </Row>
        </Container>
    </>;


}

export default SequencesPage;