import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {useStoreState, useStoreActions} from 'easy-peasy';
import { Col, Container,  Alert,  Row, ToggleButton, ToggleButtonGroup, Button } from 'react-bootstrap';

import QuizTableComponent from '../../components/quizzes/quizList/quizTable/QuizTableComponent';
import QuizBoardComponent from '../../components/quizzes/quizList/quizBoard/QuizBoardComponent';

import Loader from "../../components/widgets/Loader";
import AuthorizationChecker from '../../services/AuthorizationChecker';
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState';
import useSearchWithParams from '../../hooks/useSearchWithParams';

import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";

import "./QuizzesPage.scss";
import HelperMetiers360 from '../../services/HelpersMetiers360';

const QuizTableWithParams = withURLParamsTableComponent(QuizTableComponent);


const QuizzesPage = () => {

    const {allQuizzes, isFetchingAllQuizzes} = useCustomGetStoreState('quizzes');

    const quizzesSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("quizzes"));

    const viewStylePreference = useStoreState(state => state.preferences.quizListStyle);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setQuizListStyle);

    const quizBoardSortType = useStoreState(state => state.preferences.quizBoardSortType);
    const setQuizBoardSortType = useStoreActions(actions => actions.preferences.setQuizBoardSortType);


    const [filteredQuizzes, setFilteredQuizzes] = useState(allQuizzes);

    const viewStyleType=[
        {value : 'table', label:<><i className="fas fa-table"></i></>, view : <QuizTableWithParams quizzes = {filteredQuizzes} {...quizzesSlugs} /> },
        {value : 'cards', label:<><i className="fas fa-address-card"></i></>, view : <QuizBoardComponent quizzes = {filteredQuizzes} sortTypePreferenceTuple={[quizBoardSortType, setQuizBoardSortType]} {...quizzesSlugs} />},
    ];

    const [viewStyle, setViewStyle] = useState(viewStylePreference);

    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value == viewStyle}>{type.label}</ToggleButton>
    );


    const createQuizButton = quizzesSlugs.create
        ? <Button variant="success" as={Link} to={quizzesSlugs.create? quizzesSlugs.create : ""}><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>
        : null;

    useEffect(() => {
        setFilteredQuizzes(allQuizzes);
    }, [allQuizzes])

    const { searchInput, search } = useSearchWithParams({ placeholder: 'Rechercher par mots-clés (titre, producteur)', delay: 400, width: "35vw" })

    useEffect(() => {
        setFilteredQuizzes([...allQuizzes.filter(quiz => 
            HelperMetiers360.isSearchInText(search, quiz.name)
            || HelperMetiers360.isSearchInText(search, quiz.producers?.map((producer) => producer.clientName)?.join(''))
            || (quiz.isDirectlyAccessible && HelperMetiers360.isSearchInText(search, quiz.directAccessCode)))]
        );
	}, [search, allQuizzes]);

    const isNoQuiz = !isFetchingAllQuizzes && !allQuizzes.length
    const isNoResult = !isFetchingAllQuizzes && allQuizzes.length && !filteredQuizzes.length;

    return <Container fluid>
        <Row className="item-page">
            <Col className='m360-col3-start'>
                {searchInput}
            </Col>
            <Col className='m360-col3-center'>
                <ToggleButtonGroup type="radio" name="options" value={viewStyle} onChange={handleChange}>
                    {checkViewStyle}
                </ToggleButtonGroup>
            </Col>
            <Col className='m360-col3-end'>
                {AuthorizationChecker.hasCreateRights('quizzes') ? createQuizButton : ""}
            </Col>
        </Row>
        <Row>
            {(isFetchingAllQuizzes) && <Loader />}
            {filteredQuizzes.length > 0 && viewStyleType.filter(type => type.value === viewStyle)[0].view}
            {isNoResult && <Alert className="mt-3" variant="warning"> Aucun quiz ne correspond à votre recherche</Alert>}
            {isNoQuiz && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun quiz</Alert>}
        </Row>
    </Container>;
}

export default QuizzesPage;