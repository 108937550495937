import React, {useState, useEffect, useMemo} from "react";
import { Container, Row, Col , Button, Alert} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useModal } from "../../../../hooks/useModal";
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState";
import ClientInvoicesComponent from "./ClientInvoicesComponent";
import ClientContactsComponent from "./ClientContactsComponent";
import ClientAddressesComponent from "./ClientAddressesComponent";
import SubscriptionFormComponent, { submitSubscriptionForm } from '../../../subscription/uploadForm/SubscriptionFormComponent.jsx';
import PreparatoryOrderTableComponent from "../../../../components/orders/preparatoryOrders/PreparatoryOrderTableComponent";
import Loader from "../../../widgets/Loader";
import CreateUpdateUserComponent, {submitUserForm} from "../../../users/CreateUpdateUserComponent";
import AddressFormComponent, {submitAddressForm} from "../../../address/AddressFormComponent.jsx";
import ClientCRMMergeComponent from "./ClientCRMMergeComponent";
import './ClientCRMDetailViewcomponent.scss';
import ClientCRMInteractionsComponent from "./ClientCRMInteractionsComponent/ClientCRMInteractionsComponent.jsx";
import { useToast } from "../../../../hooks/useToast.jsx";
import ClientFormComponent, { submitClientForm } from "../../clientForm/ClientFormComponent.jsx";
import ReturnOrdersTableComponent from "../../../orders/returnOrders/ReturnOrdersTableComponent.jsx";
import HelperMetiers360 from "../../../../services/HelpersMetiers360.js";
import withURLParamsTableComponent from "../../../../HOC/withURLParamsTableComponent.jsx";
import useDebounceSearch from "../../../../hooks/useDebounchSearch.jsx";

const ClientAddressesComponentWithParams = withURLParamsTableComponent(ClientAddressesComponent);
const ClientContactsComponentWithParams = withURLParamsTableComponent(ClientContactsComponent);
const ReturnOrdersTableComponentWithParams = withURLParamsTableComponent(ReturnOrdersTableComponent);

const ClientCRMDetailViewComponent = (props) => {
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const {client:initDataClient, linkToClientPage = true, displayInteractions = false} = props;
    const {updateClientCRM } = useStoreActions(actions => actions.clients);

    const [clickedSubsCreate, setSubsClickedCreate] = useState(false);
    const [mergeClient, setMergeClient] = useState(true);
    const [subscriptionCreated, setSubscriptionCreated] = useState(false);
    const [interactionsCount, setInteractionsCount] = useState(null);
    
    const [isAdressOpen, setIsAdressOpen] = useState(false);
    const [isContactsOpen, setIsContactsOpen] = useState(false);
    const [isInvoicesOpen, setIsInvoicesOpen] = useState(false);
    const [isPreparatoryOrdersOpen, setIsPreparatoryOrdersOpen] = useState(false);
    const [isReturnOrdersOpen, setIsReturnOrdersOpen] = useState(false);
    const [isInteractionsOpen, setIsInteractionsOpen] = useState(false);

    const {allClients} = useCustomGetStoreState('clients');
    const { allUsers } = useCustomGetStoreState("users");
    const clientActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('clients'));
    const orderActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('orders'));
    const returnOrderActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('returnOrders'));
    const { fetchClientCRMById, fetchAddressesByClientId} = useStoreActions(actions => actions.clients);
    const { fetchOrdersByClientId, fetchReturnOrdersByClientId } = useStoreActions(actions => actions.orders);
    const { allOrdersByClientId, allReturnOrdersByClientId } = useStoreState(state => state.orders);
    const { clientCRMById, addressesByClientId, isFetchingAddressesByClientId} = useStoreState(state => state.clients);
    const { createContact} = useStoreActions(actions => actions.users);
    const {setCreateFormErrors } = useStoreActions(actions => actions.users);
    const { createClientAddress } = useStoreActions(actions => actions.clients);
    const toast = useToast();
    const {createSubscription} = useStoreActions(actions => actions.subscriptions);
    
    const {
        searchInput: searchInputContacts, 
        search: searchContacts, 
        resetSearchBar: resetSearchBarContacts
    } = useDebounceSearch({ placeholder: 'Rechercher par mots-clés (e-mail, nom, prénom...)', delay: 400 });
    const [filteredContacts, setfilteredContacts] = useState(clientCRMById?.contacts);

    useEffect(() => {
        fetchClientCRMById(initDataClient.uniqueId);
        fetchOrdersByClientId(initDataClient.uniqueId);  
        fetchAddressesByClientId(initDataClient.uniqueId);
        fetchReturnOrdersByClientId(initDataClient.uniqueId);
        resetSearchBarContacts();
    },[]);

	useEffect(() => {
        setfilteredContacts(clientCRMById?.contacts
            ?.filter(contact => HelperMetiers360.isSearchInText(searchContacts, contact.firstName)
                || HelperMetiers360.isSearchInText(searchContacts, contact.lastName)
                || HelperMetiers360.isSearchInText(searchContacts, contact.email)
                || HelperMetiers360.isSearchInText(searchContacts, contact.job)
            )
        );
	}, [searchContacts, clientCRMById]);

    const allUsersEmails = useMemo(() => {
        return allUsers
            ? allUsers.map((u) =>{return {email: u.email, clientName: u.clientName, clientUniqueId: u.clientUniqueId}})
            : [];
    }, [allUsers]);
    
    const clientContactEmailsList = useMemo(() => {
        return clientCRMById
            ? clientCRMById.contacts?.map(c => c.email)
            : [];
    }, [clientCRMById]);

    const clientUsersEmailList= useMemo(() => {
        if (initDataClient.usersList) {
            return initDataClient.usersList?.map(u => u.email);
        }
        if (allUsers) {
            return allUsers.filter(u => u.clientUniqueId === initDataClient.uniqueId).map(u=>u.email);
        }
        return [];
    }, [initDataClient, allUsers]);
    
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();

    const categories = (typeof clientCRMById?.categories == 'object')
        ? clientCRMById?.categories?.sort()?.join(', ')
        : clientCRMById?.categories;
    const tagOperations = (typeof clientCRMById?.tagOperationsName == 'object')
        ? clientCRMById?.tagOperationsName?.sort()?.join(', ')
        : clientCRMById?.tagOperationsName;

    const isSubscriber = clientCRMById?.isSubscriber || allClients.some((clientInArray) => clientInArray.uniqueId === clientCRMById?.uniqueId);

    const getClientDetailsLink = isSubscriber && linkToClientPage &&
        <Link to={clientActionSlugs?.readOne?.replace(':uniqueId', clientCRMById?.uniqueId)}>
            <i className="fas fa-external-link-alt me-2"></i>Lien détails Établissement</Link>;
       
    const createOrderLink = isSubscriber &&
        <Button as={Link} variant="success" to={orderActionSlugs?.create?.replace(':uniqueId', clientCRMById?.uniqueId)} >
            &nbsp;<i className="fas fa-plus mr-2"></i>Créer un bon de préparation
        </Button>;
    const createReturnOrderLink = isSubscriber &&
        <Button as={Link} variant="success" to={returnOrderActionSlugs?.create?.replace(':uniqueId', clientCRMById?.uniqueId)} >
            &nbsp;<i className="fas fa-plus mr-2"></i>Créer un bon de retour
        </Button>;


    const addContact = ()=>{
            const validate = () => {
                setIsSending(true);
                const {formName} = ACTIONS['users']['createContact'];
                return submitUserForm(createContact, formName ,clientCRMById?.uniqueId, clientContactEmailsList, null, false)
                    .then(() => {
                        toast.success();
                        setModalShow(false);
                    })
                    .catch((error) => {
                        if (error?.formError) {
                            setCreateFormErrors(error.formError);
                        }
                })
                .finally(() => setIsSending(false));
            }
            setModalData({
                ...modalData,
                header: <>Ajout d'un contact</>,
                content: <CreateUpdateUserComponent action="createContact" />,
                cancelButton: "Annuler",
                onValidate: validate,
                size: 'lg'
            });
            setModalShow(true);
    }


    const addAddress = ()=>{
        const {formName} = ACTIONS['clientsCRM']['addresses']['create'];

        const handleSubmit = () => {
            setIsSending(true);
            const result = submitAddressForm({formName, action: createClientAddress, uniqueId: clientCRMById?.uniqueId});

            result
                .then(() => {
                    toast.open({ message: "Création de l\'adresse réalisée avec succès", variant: "success" });
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Ajout d'adresse</>,
            content: <AddressFormComponent formName={formName} />,
            cancelButton: 'Annuler',
            onValidate: handleSubmit,
            size: 'lg',
        });
        setModalShow(true);
    }

    const editClientModal = () => {
        const handleSubmit = () => {
            setIsSending(true);
            const result = submitClientForm({action: 'update', client: clientCRMById, updateClientCRM});
            result
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Edition du client</>,
            content: <ClientFormComponent client={clientCRMById} action='update' />,  
            cancelButton: 'Annuler',
            onValidate: handleSubmit,
            size: 'xl', 
        });
        setModalShow(true);
    }

    const colClientContactsM360 = clientCRMById?.contactsM360
        ?.sort((a, b) => a.relation?.localeCompare(b.relation))
        ?.filter((client) => client.relationValue === 'sav')
        ?.map((contactM360, index) => 
            <div key={contactM360.relation.replace(/ /g, '') + index}>
                {contactM360.name}
            </div>);
    const colCommercialContactsM360 =  clientCRMById?.contactsM360
        ?.sort((a, b) => a.relation?.localeCompare(b.relation))
        ?.filter((client) => client.relationValue === 'commercial')
        ?.map((contactM360, index) => 
            <div key={contactM360.relation.replace(/ /g, '') + index}>
                {contactM360.name}
            </div>);

    const addSubscription = () =>  {
        setIsSending(true);
        submitSubscriptionForm({action:'create', client: clientCRMById, createSubscription:createSubscription})
            .then(() => {
                toast.success();
            })
            .finally(() => setIsSending(false));
    }

    const subscriptionCreationHandler = mergeClient
        ? <ClientCRMMergeComponent client={clientCRMById} setMergeClient={setMergeClient}/>
        : <div className="d-flex flex-column align-items-center">
            <SubscriptionFormComponent client={clientCRMById} action='create'/>
            <Button 
                onClick={addSubscription}
                className="d-flex mt-2"
                variant= "success">
                <i className="fas fa-check me-2 text-white"></i>Valider
            </Button>
        </div>

    if(clientCRMById?.length === 0) {
        return <Alert variant="danger">Erreur : Aucun Client CRM n'est rattaché à ce client.</Alert>
    }

    return <Container>
        {clientCRMById && allOrdersByClientId
            ? <>
                <Row>
                    <Row className="row-smallspace">
                        <Col className="col-maininfo">
                            <strong>{clientCRMById?.name}</strong><br />
                            {clientCRMById?.defaultAddress?.addressStreet}<br />
                            {clientCRMById?.defaultAddress?.addressZipCode}&nbsp;{clientCRMById?.defaultAddress?.addressCity}<br />
                            {clientCRMById?.defaultAddress?.addressRegion && clientCRMById?.defaultAddress?.addressRegion +'\n'}
                            {clientCRMById?.defaultAddress?.addressCountry}<br />
                        </Col>
                        <Col className="col-midspace">
                            <strong>Catégorie&nbsp;:</strong>&nbsp;{categories ?? <em>Pas de catégorie renseignée</em>}
                            <br/>
                            <strong>Opération&nbsp;:</strong>&nbsp;{tagOperations ?? <em>Pas d'opération renseignée</em>}
                            <br/>
                            <strong>Statut&nbsp;:</strong>&nbsp;{clientCRMById?.status?.value ? clientCRMById.status?.label : <em>Pas de statut renseigné</em>}
                        </Col> 
                        <Col className="col-midspace">
                            <Row>
                                <strong>Chargés de clientèle :</strong>
                                {colClientContactsM360?.length > 0 
                                    ? colClientContactsM360 
                                    : <div>Aucun</div>}
                            </Row>
                            <br/>
                            <Row>
                                <strong className="vertical-align m-0">Contacts commerciaux :</strong>
                                {colCommercialContactsM360?.length > 0 
                                    ? colCommercialContactsM360 
                                    : <div>Aucun</div>}
                            </Row>
                        </Col>
                    </Row>
                    <Row className="row-allcenter">
                        <Col className="col-allcenter">
                            {clientCRMById?.idAxonaut  && clientCRMById?.isActiveAxonaut && 
                                <a href={"https://axonaut.com/business/company/show/"+clientCRMById?.idAxonaut}
                                    target='_blank'
                                    className="target-axonaut">
                                    <Button size="sm" className="left-button d-flex align-items-center axonaut-btn">
                                        <div className="bubbleLogoAxonaut">
                                            <span></span>
                                        </div>
                                        Lien Axonaut
                                    </Button>
                                </a>}
                            <br/>
                            { getClientDetailsLink && 
                                <Button variant="light" size="sm" className="left-button btn-larger">{getClientDetailsLink}</Button>}
                            {!isSubscriber && !subscriptionCreated &&
                            <>
                                { !clickedSubsCreate
                                    ? <Button className="d-flex align-items-center justify-content-center" variant="success" 
                                        onClick={(e) => setSubsClickedCreate(true)}>
                                            <i className="fas fa-plus"></i> &nbsp;Ajouter un abonnement
                                    </Button>
                                    : subscriptionCreationHandler
                                }   
                            </>
                            }
                            {subscriptionCreated &&
                                <Alert variant="success" className="mb-0"> Abonnement créé avec succès ! </Alert>
                            }
                            <Button variant="secondary" className="ml-3 pt-2 pb-2 ps-4 pe-4"  size="sm" onClick={(e) => {e.stopPropagation(); editClientModal()}}>
                                <i className="fas fa-edit me-2"></i>Éditer
                            </Button>
                        </Col>
                    </Row>
                </Row>

                { isFetchingAddressesByClientId
                    ? <Loader/>
                    : addressesByClientId?.length > 0 
                        && <>
                            <hr/>
                            <Row className="row-midspace">
                                <div className="d-flex justify-content-between me-3 clickable" 
                                    onClick={() => setIsAdressOpen(!isAdressOpen)}>
                                    <div className="d-flex align-items-center">
                                        <h4 className="m-0">Adresses</h4>
                                        <span className="ms-2">( {addressesByClientId?.length} )</span>
                                    </div>
                                    { isAdressOpen 
                                        ? <i className="fa fa-angle-up fa-lg"></i>
                                        : <i className="fa fa-angle-down fa-lg"></i>
                                    }
                                </div>
                                <div className={!isAdressOpen ? "d-none" : null}>
                                    <Row>
                                        <Col className="mt-3 d-flex align-items-center justify-content-end">
                                            <Button variant="success" className="mt-3 d-flex align-items-center justify-content-center"
                                                onClick={(e) => addAddress()}>
                                                    <i className="fas fa-plus"></i> &nbsp;Ajouter une adresse
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="row-smallspace">
                                        <ClientAddressesComponentWithParams client={clientCRMById} 
                                            addresses={addressesByClientId} downloadable={true}/>
                                    </Row>
                                </div>
                            </Row>
                        </>
                }

                <hr/> 
                <Row className="row-midspace">
                    <div className="d-flex justify-content-between me-3 clickable" onClick={() => setIsContactsOpen(!isContactsOpen)}>
                        <div className="d-flex align-items-center">
                            <h4 className="m-0">Contacts</h4>
                            <span className="ms-2">( {clientCRMById?.contacts?.length} )</span>
                        </div>
                        { isContactsOpen 
                            ? <i className="fa fa-angle-up fa-lg"></i>
                            : <i className="fa fa-angle-down fa-lg"></i>
                        }
                    </div>
                    <div className={!isContactsOpen ? "d-none" : null}>
                        <Row className="mt-3 align-items-top">
                            { clientCRMById?.contacts?.length > 0
                                && <Col xs={8}>{searchInputContacts}</Col>}
                            <Col className="d-flex align-items-center justify-content-end">
                                <Button variant="success" className="d-flex align-items-center justify-content-center" onClick={(e) => addContact()}>
                                    <i className="fas fa-plus"></i> &nbsp;Ajouter un contact</Button>
                            </Col>
                        </Row>
                        <Row className="row-smallspace">
                            { filteredContacts?.length === 0 && clientCRMById?.contacts?.length > 0
                                ? <Alert variant="warning">Aucun contact ne corrrespond à votre recherche</Alert>
                                : <ClientContactsComponentWithParams client={clientCRMById}
                                    filteredContacts={filteredContacts} 
                                    downloadable={true}
                                    allUsersEmails={allUsersEmails}
                                    clientUsersEmail={clientUsersEmailList} />
                            }
                        </Row>
                    </div>
                </Row>

                <hr />
                <Row className="row-midspace">
                    <div className="d-flex justify-content-between me-3 clickable" onClick={() => setIsInvoicesOpen(!isInvoicesOpen)}>
                        <div className="d-flex align-items-center">
                            <h4 className="m-0">Factures</h4>
                            <span className="ms-2">( {clientCRMById?.invoices?.length} )</span>
                        </div>
                        { isInvoicesOpen 
                            ? <i className="fa fa-angle-up fa-lg"></i>
                            : <i className="fa fa-angle-down fa-lg"></i>
                        }
                    </div>
                    <Row className={!isInvoicesOpen ? "d-none" : "row-smallspace"}>
                        <ClientInvoicesComponent client = {clientCRMById} />
                    </Row>
                </Row>

                <hr />
                <Row className="row-midspace">
                    <div className="d-flex justify-content-between me-3 clickable" onClick={() => setIsPreparatoryOrdersOpen(!isPreparatoryOrdersOpen)}>
                        <div className="d-flex align-items-center">
                            <h4 className="m-0">Bons de préparation</h4>
                            <span className="ms-2">( {allOrdersByClientId?.length} )</span>
                        </div>
                        { isPreparatoryOrdersOpen 
                            ? <i className="fa fa-angle-up fa-lg"></i>
                            : <i className="fa fa-angle-down fa-lg"></i>
                        }
                    </div>
                    <div className={!isPreparatoryOrdersOpen ? "d-none" : null}>
                        { createOrderLink 
                            && <Row>
                                <Col className="mt-3 d-flex align-items-center justify-content-end">
                                    {createOrderLink}
                                </Col>
                            </Row>}
                        <Row className="row-smallspace">
                            {allOrdersByClientId?.length > 0 
                                ? <PreparatoryOrderTableComponent orders={allOrdersByClientId} /> 
                                : <div>Pas de bons de preparation</div>
                            }
                        </Row>
                    </div>
                </Row>
            
                { displayInteractions
                    && <>
                        <hr />
                        <Row className="row-midspace">
                            <div className="d-flex justify-content-between me-3 clickable" onClick={() => setIsInteractionsOpen(!isInteractionsOpen)}>
                                <div className="d-flex align-items-center">
                                    <h4 className="m-0">Suivi client</h4>
                                    <span className="ms-2">
                                        ( {initDataClient.uniqueId === clientCRMById?.uniqueId && interactionsCount } )
                                    </span>
                                </div>
                                { isInteractionsOpen 
                                    ? <i className="fa fa-angle-up fa-lg"></i>
                                    : <i className="fa fa-angle-down fa-lg"></i>
                                }
                            </div>
                            <Row className={!isInteractionsOpen ? "d-none" : "row-smallspace pe-0"}>
                                { initDataClient.uniqueId === clientCRMById?.uniqueId
                                    ? <ClientCRMInteractionsComponent clientId={clientCRMById?.uniqueId} 
                                        setInteractionsCount={setInteractionsCount} />
                                    : <Loader />
                                }
                            </Row>
                        </Row>
                    </>
                }

                <hr />
                <Row className="row-midspace">
                    <div className="d-flex justify-content-between me-3 clickable" onClick={() => setIsReturnOrdersOpen(!isReturnOrdersOpen)}>
                        <div className="d-flex align-items-center">
                            <h4 className="m-0">Bons de retour</h4>
                            <span className="ms-2">( {allReturnOrdersByClientId?.length} )</span>
                        </div>
                        { isReturnOrdersOpen 
                            ? <i className="fa fa-angle-up fa-lg"></i>
                            : <i className="fa fa-angle-down fa-lg"></i>
                        }
                    </div>
                    <div className={!isReturnOrdersOpen ? "d-none" : null}>
                        { createReturnOrderLink 
                            && <Row>
                                <Col className="mt-3 d-flex align-items-center justify-content-end">
                                    {createReturnOrderLink}
                                </Col>
                            </Row>}
                        <Row className="row-smallspace">
                            {allReturnOrdersByClientId?.length > 0
                                ? <ReturnOrdersTableComponentWithParams orders={allReturnOrdersByClientId} /> 
                                : <div>Pas de bons de retour</div>
                            }
                        </Row>
                    </div>
                </Row>

                {modalComponent}
            </>  
            : <Loader />}
    </Container>
}

export default ClientCRMDetailViewComponent;