import React, {useEffect, useRef} from 'react';
import { useStoreState } from 'easy-peasy';
import {Row, Col, Image, Card, ListGroup, Alert, Container, Button, ListGroupItem} from 'react-bootstrap';

import HelperMetiers360 from '../../../services/HelpersMetiers360.js';

import AuthorizationChecker from '../../../services/AuthorizationChecker.js';

import CardGroupMediaComponent from '../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx';

import { Link, useNavigate } from "react-router-dom";
import { useModal } from '../../../hooks/useModal';
import RomeComponent from '../../romes/RomeComponent.jsx';


import './StoryDetailsComponent.scss';
import Loader from '../../widgets/Loader.jsx';

const StoryDetailsComponent = (props) => {

    const { story, deleteStory, storySequences, storyVideos, isFetchingSequences, isFetchingAllVideos, storyRomes} = props;

    const storySlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('stories'));
    const clientSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));
    const videoSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));
    // const { storiesPageLink } = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('stories'));

    const { modalComponent, setModalShow, modalData, setModalData } = useModal();

    const { readOne: readOneSequence } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('sequences'));
    const { readOne: readOneVideo } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));

    const navigate = useNavigate();

    const romeList = story.romes?.length > 0 ?
        <RomeComponent 
            romeList={story.romes} 
            isClickable = {AuthorizationChecker.hasReadOneRights('romes')}
            />
        : <>Pas de codes ROME enregistrés</>;

    const privateStatus = story.private !== undefined &&
        story.private ?
            <><i className="fas fa-lock" />&nbsp; Story privée</>
            : <><i className="fas fa-lock-open" />&nbsp; Story publique</>
    
    const featuredStatus = story?.featured ?
        <><ListGroup.Item><i className="fas fa-star" />&nbsp; Cette story est mise en avant</ListGroup.Item></>
        :null;

    const sequenceOnlyStatus = story?.sequenceOnly ?
        <><ListGroup.Item><i className="fas fa-list-ol" />&nbsp; Cette story est dans un parcours uniquement</ListGroup.Item></>
        :null;
    

    const freemiumStatus = story?.freemium ?
        <><ListGroup.Item><i className="fab fa-creative-commons-nc-eu" />&nbsp; Cette story est freemium</ListGroup.Item></>
        :null;

    const producersList = story?.producers?.length > 0 ?
        story.producers
            .map((producer) => (
            <ListGroup.Item as={Link} key={producer.id} action to={clientSlugs.readOne.replace(':uniqueId', producer.id)}><i className="mr-2"/> {producer.clientName}</ListGroup.Item>
            ))
        : null;

    const typeOfStory = story?.typeOfStory && story.typeOfStory.label === "extrait 360" ?
        <Link to={videoSlugs.readOne.replace(':uniqueId', story.fromVideoUniqueId)}>{story.typeOfStory.label}</Link>
        : story?.typeOfStory && story.typeOfStory.label && <>{story.typeOfStory.label}</>;

    const formatVideoSource = () => {
        const format = story.links?.media?.split('.')?.at(-1);
        if(story.type === 'video360' || story.type === 'video') {
            if(format == 'mov' || format == 'm4v') {
                return 'mp4';
            }
            return format;
        }
        return null;
    }

    const mediaSourceLink = formatVideoSource()
        && HelperMetiers360.checkVideoFormatSupport(formatVideoSource())
        && story.links.altMedia ?
        story.links.altMedia
        : story.links.media;

    const showMedia = () => {
        setModalData({
            ...modalData,
            header: <>Média enregistré</>,
            content: <>
                {story.type === 'video' && <video width="100%" controls>
                    <source src={mediaSourceLink} type={"video/".concat(formatVideoSource())} /> Your browser does not support the video tag. </video>}
                {story.type === 'image' && <Image width="100%" src={story.links.media} />}
                {story.type === 'podcast' && <audio style={{width: '100%'}} controls><source src={story.links.media} /></audio>}
            </>,
            validateButton: 'Fermer',
            cancelButton: '',
        });
          
        setModalShow(true);
    }

    const isUsedInSequence = AuthorizationChecker.isAdmin() && story.isUsedInSequence;
    const isUsedInVideo = !!story.fromVideo;
    const canBeDeleted = !isUsedInSequence && !isUsedInVideo;

    const createDeleteModal = () => {
        setModalData({
            ...modalData,
            header: <>Suppression de la story</>,
            content: <> 
                {isUsedInSequence && 
                    <Alert variant="danger">
                        <h5>Cette story est utilisée dans &nbsp;<b>{storySequences.length}</b> séquence{storySequences.length > 1 && 's'}.</h5>
                    </Alert>}
                {isUsedInVideo &&
                    <Alert variant="danger">
                        <h5>Cette story est un extrait de l'expérience immersive : {story.fromVideo}</h5>
                    </Alert>}
                {canBeDeleted ? 
                    <h5>Êtes-vous sûr⋅e de vouloir supprimer la story <b>{story.name}</b>&nbsp;?</h5>
                    :<h5>Veuillez supprimer la story de ce(s) containers pour pouvoir continuer</h5> }
            </>,
            resetButton: 'Supprimer',
            cancelButton: canBeDeleted && 'Annuler',
            onReset: canBeDeleted ?
            () => {
                deleteStory({ uniqueId: story.uniqueId })
                    .then(() => {
                        redirect(storySlugs.readAll);
                    });
            }
            : null,
        });
        setModalShow(true);
    }

    const redirect = (url) => {
        navigate(url);
    }

    const listGroup = useRef(null);

    useEffect(() =>{
        if(listGroup.current.children.length == 0) {
            listGroup.current.remove();
        }
    },[story])

    return (
        <>
        <Container className="item_details">
                <Row>
                    <Col>
                        <Card className="item_details-card">

                            <Card.Body className="item_details-body">
                                {story?.links?.thumbnail && <Image className="item_details-thumbnail" src={story.links.thumbnail.default}/>}
                                <Card.Title className="item_details_card_title">
                                    {story && story.name ? story.name : "Détails de la story"}
                                </Card.Title>
                                {
                                    (AuthorizationChecker.hasUpdateRights('stories') || AuthorizationChecker.hasDeleteRights('stories'))
                                        && <div className="item_details_btn_actions">
                                                {
                                                    AuthorizationChecker.hasUpdateRights('stories') &&
                                                    <Button variant="secondary" as={Link} to={storySlugs.update.replace(':uniqueId', story && story.uniqueId)}>
                                                        <i className="fas fa-edit"></i> &nbsp;Éditer
                                                    </Button>
                                                }
                                                {   AuthorizationChecker.hasDeleteRights('stories') &&
                                                    <Button variant="danger" onClick={createDeleteModal}><i className="fas fa-trash-alt"></i> &nbsp;Supprimer</Button>
                                                }
                                            </div>
                                } 
                
                                <ListGroup ref={listGroup}>
                                    {story.releaseDate != null && <ListGroup.Item>
                                            <strong>Publication :</strong> {HelperMetiers360.getdisplayDateType(story.releaseDate, 'day')}
                                    </ListGroup.Item>}
                                    {story.lastUpdated  != null && <ListGroup.Item>
                                            <strong>Mise à jour :</strong> {HelperMetiers360.getdisplayDateType(story.lastUpdated, 'day')}
                                    </ListGroup.Item>}
                                    {story.position  != null && <ListGroup.Item>
                                            <strong>Position : </strong>{story.position}
                                    </ListGroup.Item>}
                                    {privateStatus  != null && <ListGroup.Item>
                                            <strong>Statut : </strong> {privateStatus}
                                    </ListGroup.Item>}
                                    {featuredStatus} 
                                    {sequenceOnlyStatus}
                                    {freemiumStatus} 
                                    {producersList  != null && <ListGroup.Item>
                                        <strong>Producteurs : </strong>
                                            {producersList}
                                    </ListGroup.Item>}
                                    {typeOfStory  != null && <ListGroupItem>
                                        <strong>Type : </strong> { typeOfStory }
                                    </ListGroupItem>}
                                    <ListGroup.Item>
                                        {story.type  != null && <><strong>Média : </strong>[{story.type}]</>}
                                        {story.fileSize  != null && <>- <strong>Taille :</strong> {HelperMetiers360.formatBytes(story.fileSize)}</>}
                                    </ListGroup.Item>
                                    <ListGroup.Item  className='click-media' onClick={showMedia}><i className="icon-plus-square-M360"/> Voir le média enregistré</ListGroup.Item >
                                    <ListGroup.Item >
                                        {(story.links.external) ? <> <strong>Lien externe : <a href={story.links.external}>{story.links.external}</a></strong></> : <><strong>Lien externe : </strong> Pas de lien externe enregistré</>}
                                    </ListGroup.Item>
                                    <ListGroup.Item><strong>Métier{story.romes?.length > 1 && 's'} :</strong> 
                                    <ListGroup.Item>{romeList}</ListGroup.Item>
                                    </ListGroup.Item>
                                    {isUsedInSequence}
                                    {isUsedInVideo}
                                </ListGroup>

                                <div className="item_details_description">
                                { 
                                story.description &&
                                <>
                                    <Card.Title className="item_details_description_title">
                                        Description
                                    </Card.Title>
                                
                                <span className="text-muted" dangerouslySetInnerHTML={{__html: story.description}}></span>
                                    </>
                                }
                                <hr />
                                { 
                                story.greenfact &&
                                <>
                                    <Card.Title className="item_details_description_title">
                                        Greenfact
                                    </Card.Title>
                                <span className="text-muted" dangerouslySetInnerHTML={{__html: story.greenfact}}></span>
                                </>
                                }
                                </div>

                                <div className="associated_card">
                                    {isUsedInSequence && !isFetchingSequences && 
                                        <div className="associated_card_item">
                                            <Card.Title className="associated_card_title">
                                                Parcours
                                            </Card.Title>
                                        <CardGroupMediaComponent mediaType="sequence" mediaList={storySequences} readOne={readOneSequence} />
                                        </div>
                                    }
                                    {isUsedInVideo && !isFetchingAllVideos &&
                                        <div className="associated_card_item">
                                            <Card.Title className="associated_card_title">
                                                Expériences immersives
                                            </Card.Title>
                                        <CardGroupMediaComponent mediaType="video" mediaList={storyVideos} readOne={readOneVideo} />
                                        </div>
                                    }
                                    {(isFetchingAllVideos || isFetchingSequences) &&  <Loader />}
                                </div>
                             </Card.Body>
                        </Card>
                        </Col>
                        </Row>
                {modalComponent}
            </Container>
        </>
    );
}

export default StoryDetailsComponent;
