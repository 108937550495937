import React from "react";

import {useStoreActions, useStoreState} from 'easy-peasy';
import { Col, Row } from "react-bootstrap";

import UploadVideoComponent from "../../components/videos/UploadVideoComponent";
import useBackButton from "../../hooks/useBackButton";

const UploadVideoPage = (props) => {
	const {postVideo, updateVideo} =  useStoreActions((actions) => actions.videos);
    const videoSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("videos"));

	const {backButtonComponent} = useBackButton({
        path: videoSlugs.readAll, 
        mediaType:"videos", 
        shouldReplaceHistory:true, 
    })


	return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
                    <h2>Ajout d'une expérience immersive</h2>
					{backButtonComponent}
				</Col>
			</Row>
            {
                <UploadVideoComponent 
					postVideo={postVideo} 
					updateVideo={updateVideo} 
					action="create"
					{...videoSlugs}
				/>
            }
		</div>
	);
};

export default UploadVideoPage;
