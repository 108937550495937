import React from "react";
import { useNavigate } from "react-router-dom";
import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import DynamicTable from "../../../widgets/dynamicTable/DynamicTable.jsx";
import HelperMetiers360 from "../../../../services/HelpersMetiers360.js";
import TooltipListComponent from "../../../widgets/tooltipList/TooltipListComponent.jsx";
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState.jsx";
import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";

const QuizTableComponent = ({quizzes, withParams }) => {
    const navigate = useNavigate();
    const quizzesToDisplay = quizzes ?? [];

    const getListGroupSize = (quiz) => {
        return quiz.groups ? quiz.groups.length:0;
    };

    const quizzesSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('quizzes'));
    const { allPedagogicModules, isFetchingAllPedagogicModules} = useCustomGetStoreState("pedagogicModules")

    const listPedagogicModules = (quiz) => {
        const pedagogicModules = allPedagogicModules
        .filter(pedagogicModule => ( pedagogicModule.quizzes
        .some(q => q.uniqueId === quiz.uniqueId)
        ));

        return pedagogicModules;
    };

    const producersName = (producers) => producers.map((producer) => producer.clientName ).map(String)?.join(', ');

    if (quizzes.length === 0 )
        {return <></>;}

    const thumbnailDisplay = (quiz) =>  <OverlayTrigger
            placement="right"
            overlay={
                <Tooltip>
                    <Image src={quiz.links.thumbnail}/>
                </Tooltip>
            }    
        >
            <Image src={quiz.links.thumbnail} className="img-overlay" thumbnail />
        </OverlayTrigger>;

    const groupsDisplay = (quiz) => {
        if (!quiz.groups) {
            return <span>0</span>
        }
        
        const generateIdCallback = (uniqueId) => {
            return `group-${uniqueId}${quiz.uniqueId}`;
        }

        return <TooltipListComponent
            inputList={quiz.groups}
            keyToDisplay='name'
            generateIdCallback={generateIdCallback}
         />
    }

    const pedagogicModulesDisplay = (quiz) => {
        const pedagogicModuleList = listPedagogicModules(quiz);
        if (pedagogicModuleList?.length === 0) {
            return <span>0</span>
        }

        const generateIdCallback = (pedagogicModuleUniqueId) => {
            return `pedagogicModule-${pedagogicModuleUniqueId}`
        }

        return <TooltipListComponent
            inputList={pedagogicModuleList}
            keyToDisplay='name'
            generateIdCallback={generateIdCallback} 
            isFetching={isFetchingAllPedagogicModules}/>
    }

    const sortType = [
        {
            value: 'thumbnail', label: 'Vignette',
            test : HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "links"),
            method: null,
            display: (quiz) => thumbnailDisplay(quiz),
            flatDisplay: (quiz) => quiz?.links?.thumbnail
        },
        {
            value: 'title', label: "Titre",
            test : HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "name"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (quiz) => quiz.name
        },
        {
            value: 'groups', label: 'Groupes',
            test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "groups"),
            method: (a, b) => getListGroupSize(a) - getListGroupSize(b),
            display: (quiz) => groupsDisplay(quiz),
            flatDisplay: (quiz) => quiz?.groups?.length
        },
        {
            value : 'pedagogicModules', label : 'Modules',
            test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "pedagogicModules"),
            method: (a,b) => listPedagogicModules(b).length - listPedagogicModules(a).length,
            display: (quiz) => pedagogicModulesDisplay(quiz),
            flatDisplay: (quiz) => listPedagogicModules(quiz).length
        },
        {
            value: 'sessions', label: "Sessions",
            test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "sessions"),
            method: (a, b) => a.sessions-b.sessions,
            display: (quiz) => quiz.sessions
        },
        {
            value: 'position', label: "Position",
            test : HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "displayOrder"),
            method: (a, b) => b.displayOrder - a.displayOrder,
            display: (quiz) => quiz.displayOrder
        },
        {
            value: 'date', label: "Publication",
            test : HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "releaseDate"),
            method: (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate),
            display: (quiz) => HelperMetiers360.getdisplayDateType(quiz.releaseDate, 'day')
        },
        {
            value: 'lastUpdated', label: "Mise à jour",
            test : HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "lastUpdated"),
            method: (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated),
            display: (quiz) => HelperMetiers360.getdisplayDateType(quiz.lastUpdated, 'day')
        },
        {
            value: 'producers', label: "Producteur",
            test : HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "producers"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(producersName(a.producers), producersName(b.producers)),
            display: (quiz) => quiz.producers.length ? producersName(quiz.producers) : '-'
        },
        {
            value: 'private', label: "Privé",
            test : HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "private"),
            method: (a, b) => {const aPrivate = a.private ? 'X':'-'; return aPrivate.localeCompare(b.private ? 'X':'-');},
            display: (quiz) => quiz.private ? 'X' : '-'
        },
        {
            value: 'directAccessCode', label: "Code d'accès",
            test : HelperMetiers360.isArrayContainsValue(quizzesToDisplay, "directAccessCode"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.isDirectlyAccessible ? a.directAccessCode : "", b.isDirectlyAccessible ? b.directAccessCode : ""),
            display: (quiz) => quiz.isDirectlyAccessible && quiz.directAccessCode
        } 
    ];

    const onSelectStory = (uniqueID) => {
        if(quizzesSlugs.readOne)
            navigate(quizzesSlugs.readOne.replace(':uniqueId', uniqueID));
    }

    return <DynamicTable 
        contentTable = {quizzesToDisplay}
        contentSort = {sortType}
        valueInitSort = "title"
        index = 'uniqueId'
        handleClick = {onSelectStory}
        withParams={withParams}
        tableName="quizzTable"
        filename="quizzes" />;
}

QuizTableComponent.propTypes = {
    quizzes: PropTypes.array.isRequired,
    withParams: PropTypes.bool
}

export default QuizTableComponent;