import React, {useState} from 'react';

import { Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import VideoFlatComponent from "./VideoFlatComponent.jsx";
import Video360Component from "./Video360Component.jsx";
import AuthorizationChecker from '../../../../services/AuthorizationChecker.js';

/**
 * 
 * @param {Object} videoById - video 
 * @returns 
 */

const VideoPlayerComponent = (props) => {
    const {videoById, playerTypePreferenceTuple, viewCount, headsetsCount} = props;

    const [playerPreference, setPlayerPreference] = playerTypePreferenceTuple;

    const videoPlayerType = [
        {value : 'flat', label: <>Lecteur vidéo</>, content: <VideoFlatComponent video={videoById}/>},
        {value : 'video360', label: <>Lecteur vidéo 360</>, content: <Video360Component video={videoById}/>},
    ];

    const [selectedVideoPlayer, setSelectedVideoPlayer] = useState(playerPreference);

    const handleChange = (value) => {
        setSelectedVideoPlayer(value);
        setPlayerPreference(value);
    }

    return (
        <>
            <Row className='align-items-center'>
                <Col md={12} xl={8}>
                    <ToggleButtonGroup
                    className='py-2' 
                    type="radio" 
                    name="options"
                    value={selectedVideoPlayer} 
                    onChange={handleChange}
                    >
                        {
                            videoPlayerType.map((style) => (
                                <ToggleButton value={style.value} key={style.value} id={style.value} className='mb-0' checked={style.value == selectedVideoPlayer}>{style.label}</ToggleButton>
                            ))
                        }
                    </ToggleButtonGroup>
                </Col>

                {AuthorizationChecker.isAdmin()
                    && <Col md={12} xl={4}>
                        <div className="videoStats">
                            <div className="videoStat left">
                                <i className='fas fa-play' /> {viewCount ?? "?"}
                            </div>
                            <div className="videoStat right">
                                {headsetsCount ?? "?"} 
                                <i className='nav-icon icon-VR-M360' />   
                            </div>
                        </div>
                    </Col>
                }
            </Row>
            <Row>
                <Col>
                    {videoPlayerType.find(type => type.value === selectedVideoPlayer).content}
                </Col>
            </Row>
        </>
    );
};

export default VideoPlayerComponent;