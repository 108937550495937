import React, { useRef, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router';
import { Col, Form, InputGroup, Alert, Row } from 'react-bootstrap';
import { Editor } from "@tinymce/tinymce-react";
import SelectMultipleItemsWithSearchBar from "../../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar.jsx";
import HelperMetiers360 from "../../../services/HelpersMetiers360";

import SelectMultipleClientsComponent from '../../widgets/selectClient/SelectMultipleClientsComponent.jsx';
import ButtonLoaderComponent from '../../widgets/ButtonLoaderComponent.jsx';
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState.jsx';

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";
import { useToast } from '../../../hooks/useToast.jsx';

registerLocale('fr', fr);


const FormationFormComponent = ({
        createFormation,
        updateFormation,
        formationToUpdate,
        action
}) => {
    const navigate = useNavigate();
    const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
	const formationsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('formations'));

	const {csrfToken, formName} = ACTIONS['formations'][action];

    const imageFileInput = useRef(null);
	const [imageFileName, setThumbnailFileName] = useState((formationToUpdate?.links?.image) ? 'changez le fichier' :'Sélectionnez un fichier');
	const pdfFileInput = useRef(null);
    const [pdfFileInputName, setPdfFileInputName] = useState(formationToUpdate?.pdfFile ? 'Changer le fichier' : 'Sélectionnez un fichier');
    const { allPedagogicModules } = useCustomGetStoreState("pedagogicModules");
    const toast = useToast();
    
    const editorContentRefDescription = useRef(formationToUpdate?.description ? formationToUpdate.description : '');
    const handleEditorChangeDescription = (content, editor) => {
        editorContentRefDescription.current = content;
    }
	
	const [isPrivate, setIsPrivate] = useState(formationToUpdate?.private ? formationToUpdate.private : undefined);
	const [selectedTools, setSelectedTools]= useState(formationToUpdate?.tools ? formationToUpdate.tools : []);
	const [selectedLevels, setSelectedLevels]= useState(formationToUpdate?.levels ? formationToUpdate.levels : []);
	const [selectedCategories, setSelectedCategories]= useState(formationToUpdate?.categories ? formationToUpdate.categories : []);

    const [selectedClients, setSelectedClients] = useState(formationToUpdate?.clients ? formationToUpdate.clients.map(client => client.uniqueId).map(String) : []);
	const [selectedPedagogicModules, setSelectedPedagogicModules] = useState(formationToUpdate?.pedagogicModules ? formationToUpdate.pedagogicModules.map(formation => formation.uniqueId).map(String) : []);
    
	const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;
	const { typeOfTool: allTools } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfLevel: allLevels } = BACKOFFICE_DEFAULT_VALUES;
	const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;
  

    const [isSending, setIsSending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

	const [formErrors, setFormErrors] = useState({});  

	const [publishDate, setPublishDate] = useState(formationToUpdate?.releaseDate ? new Date(formationToUpdate?.releaseDate) : null);


	const redirect = (url) => {
		navigate(url, {replace:true, state: {fromEdit:true}});
	}
	
    const checkData = (e) => {
	    e.preventDefault();
	    let formData = new FormData(document.getElementById('formation-form'));

	    let name = formData.get(`${formName}[name]`);
		let image = formData.get(`${formName}[image]`);
		let pdfFile = formData.get(`${formName}[pdfFile]`);

		let emptyCategoryIndex = selectedCategories.findIndex((cat)=>{
			return cat == "";
		})


		if(emptyCategoryIndex !== -1){
			selectedCategories.splice(emptyCategoryIndex, 1);
		}
				
        const errorsList = [];
        const errors = {};

	    if (!name) {
            errorsList.push('Veuillez saisir un nom pour cette formation');
            errors.name = 'Veuillez saisir un nom pour cette formation';
	    }


        if (image.size ) {
            if (image.size > fileContraints.MAX_SIZE_IMAGE_BYTES ) {
                errorsList.push("Vignette trop volumineuse");
                errors.image = "Vignette trop volumineuse";
            }
            if (!fileContraints.IMAGE_MIME_TYPES.includes(image.type) ) {
                errorsList.push("Format de la vignette non autorisé");
                errors.image = "Format de la vignette non autorisé";
            }
        }


        if (pdfFile.size ) {
            if (pdfFile.size > fileContraints.MAX_SIZE_TEXT_FILE_BYTES ) {
                errorsList.push("Fichier trop volumineux");
                errors.pdfFile = "Fichier trop volumineux";
            }
            if (!fileContraints.TEXT_FILE_MIME_TYPES.includes(pdfFile.type) ) {
                errorsList.push("Format du fichier non autorisé");
                errors.pdfFile = "Format du fichier non autorisé";
            }
        }

		if (!publishDate) {
            errorsList.push("Veuillez sélectionner une date de publication");
            errors.releaseDate = "Veuillez sélectionner une date de publication";
		}


		if (selectedCategories.length == 0 ) {
            errorsList.push('Veuillez choisir au moins une categorie pour cette formation');
            errors.categories = 'Veuillez choisir au moins une categorie pour cette formation';
	    }
	
        if (isPrivate &&  (selectedClients.length == 0 || selectedClients == "")) {
            errorsList.push('Veuillez choisir au moin un client');
            errors.clients = 'Veuillez choisir au moin un client';
	    }

        if (errorsList?.length) {
            toast.open({ message: errorsList.join(', '), variant: "danger" });
        }
        if (Object.keys(errors)?.length) {
            setFormErrors(errors);
        }

	    else {
			if (!isPrivate) {
				setSelectedClients([]);
			}
		    setIsSending(true)
		    setFormErrors({});
		    submitForm();
	    }

    }

	const handleBadRequest = (error) => {
		const errorList = {};
		if (error && error.errors) {
			for (const key of Object.keys(error.errors)) {
				errorList[key] = error.errors[key]
			}
		}
		errorList.global = 'Il y a eu un probleme lors de l\'enregistrement de la formation';
		setFormErrors(errorList);
	}




    const submitForm = () => {
	    let formData = new FormData(document.getElementById('formation-form'));
		formData.append(`${formName}[description]`, editorContentRefDescription.current);
		formData.append(`${formName}[releaseDate]`, HelperMetiers360.formatDateForFormControl(publishDate));

		let ActionFn;

        const options = {
            formData,
            uniqueId: action === 'update' ? formationToUpdate?.uniqueId : undefined
        }
       

        if (action === 'create') {
			ActionFn = createFormation;
      
        } else {
			ActionFn = updateFormation
		}
		ActionFn(options)
		.then(data => {
			const {uniqueId : createdFormationUniqueId} = data;
			setIsSuccess(true);
			setIsSending(false);
			redirect(formationsSlugs.readOne.replace(':uniqueId', createdFormationUniqueId));
		})
        .catch(error => {
			handleBadRequest(error);
			setIsSending(false)
		})
        
    }

    return (
        <div className='formation-form'>
	        <Form method="post" encType="multipart/form-data" id="formation-form" className={formName}>
				{ formErrors.global ? <Alert variant="danger"> {formErrors.global}</Alert> : null}
				{isSuccess  ? <Alert variant="success">chargement terminé</Alert> : null}
		        <Row>
			        <Col sm={12} md={6}>
				        <InputGroup>
						    <InputGroup.Text>Nom</InputGroup.Text>
					        <Form.Control
						        placeholder="Exemple: Utilisation des casques"
						        name={`${formName}[name]`}
						        isInvalid={formErrors.name !== undefined}
						        defaultValue={formationToUpdate?.name ? formationToUpdate.name : ''}
					        />
					        <Form.Control.Feedback type='invalid'>{formErrors.name}</Form.Control.Feedback>
				        </InputGroup>
			        </Col>
                    <Col sm={12} md={6}>
                    <InputGroup>
						    <InputGroup.Text>Publication</InputGroup.Text>
							<DatePicker
								showIcon
								locale='fr'
								dateFormat="dd/MM/yyyy"
								selected={publishDate}
								onChange={(date) => {
										setFormErrors((prev) => ({...prev, releaseDate: null}));
										setPublishDate(date);
									}
								} 
								placeholderText="jj/mm/aaaa"
                    		/>
							{formErrors?.releaseDate && <div className='invalid-feedback' style={{display: "block"}}>Veuillez indiquer une date de publication</div>} 

				    </InputGroup>
                    </Col>
        
		        </Row>

				<hr />

                <Row>
					<Col xs={4}>
						<SelectMultipleItemsWithSearchBar idSelector={`${formName}[categories][]`} label="Catégorie(s)"
							allItems={allCategories} 
							selectedItemKeys={selectedCategories} setSelectedItemKeys={setSelectedCategories} 
							itemKey="value" itemValue="label" />
						<Form.Control.Feedback type='invalid'>{formErrors.categories}</Form.Control.Feedback>
					</Col>
					<Col xs={4}>
						<SelectMultipleItemsWithSearchBar idSelector={`${formName}[levels][]`} label="Contexte(s)"
							allItems={allLevels} 
							selectedItemKeys={selectedLevels} setSelectedItemKeys={setSelectedLevels} 
							itemKey="value" itemValue="label" />
						<Form.Control.Feedback type='invalid'>{formErrors.levels}</Form.Control.Feedback>
					</Col>
                    <Col xs={4}>
						<SelectMultipleItemsWithSearchBar idSelector={`${formName}[tools][]`} label="Outil(s)"
							allItems={allTools} 
							selectedItemKeys={selectedTools} setSelectedItemKeys={setSelectedTools} 
							itemKey="value" itemValue="label" />
						<Form.Control.Feedback type='invalid'>{formErrors.tools}</Form.Control.Feedback>
                    </Col>
		        </Row>


				<hr />

				<Row>
			       
					<Row>
					   <Form.Group as={Col} sm={12} md={12}  >
				        <Form.Label>Description</Form.Label>
						<Editor
                            name={`${formName}[description]`}
                            apiKey="t5rspxvw6u2zr48fduj1kf3twvxk7dsncf5bk8h50v07s8lg"
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
								'searchreplace', 'visualblocks', 'code', 'fullscreen',
								'insertdatetime', 'media', 'table', "code", 'help', 'wordcount', 'emoticons'
                                ],
                                toolbar:
                                'undo redo | fontsize blocks | bold italic backcolor | \
								alignleft aligncenter alignright alignjustify | \
								bullist numlist outdent indent | charmap emoticons | link | removeformat | help | media'
                            }}
                            onEditorChange={handleEditorChangeDescription}
                            initialValue={formationToUpdate?.description ? formationToUpdate.description : ""  }
                        />
				        <Form.Control.Feedback type='invalid'>{formErrors.description}</Form.Control.Feedback>
			            </Form.Group>
                    </Row>
				</Row>



				<hr />

				<Row>
			       
					<Row>
					<Col sm={12} md={6}>
				        <InputGroup>
						    <InputGroup.Text>Vignette ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_MO} Mo max.)</InputGroup.Text>
					        <div className="custom-file">
						        <input
							        id="imageInput"
							        type="file"
							        name={`${formName}[image]`}
							        className={"custom-file-input" + (formErrors.image != undefined ? " is-invalid" : "")}
							        accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
							        ref={imageFileInput}
							        onChange={() => setThumbnailFileName(imageFileInput?.current?.files[0]?.name)}
						        />

						        <label className="custom-file-label" htmlFor="imageInput" data-browse="Parcourir">
							        {imageFileName}
						        </label>
					        </div>
					        <Form.Control
						        type="hidden"
						        isInvalid={formErrors.image !== undefined}
					        />
					        <Form.Control.Feedback type='invalid'>{formErrors.image}</Form.Control.Feedback>
				        </InputGroup>
			        </Col>
                        <Col sm={12} md={6}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputFormationFileAddon01">
                                        Fichier ({fileContraints.TEXT_FILE_MIME_TYPES_NAMES?.join(',')}) ({fileContraints.MAX_SIZE_TEXT_FILE_MO} Mo max.)
                                    </span>
                                </div>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        name={`${formName}[pdfFile]`}
                                        className={"custom-file-input" + (formErrors.pdfFile ? " is-invalid" : null)}
                                        id="inputFormationFile01"
                                        aria-describedby="inputFormationFileAddon01"
                                        accept={fileContraints.TEXT_FILE_MIME_TYPES?.join(',')}
                                        ref={pdfFileInput}
                                        onChange={() => setPdfFileInputName(pdfFileInput?.current?.files[0]?.name)} />

                                    <label className="custom-file-label" htmlFor="inputFormationFile01" data-browse="Parcourir">
                                        {pdfFileInputName}
                                    </label>
                                </div>
                            </div>
							<Form.Control
						        type="hidden"
						        isInvalid={formErrors.pdfFile !== undefined}
					        />
                        <Form.Control.Feedback type='invalid'>{formErrors.pdfFile}</Form.Control.Feedback>
                        </Col>
                    </Row>
				</Row>



				<hr />

				<Form.Check type='checkbox' id='formation_private' name={`${formName}[private]`} inline>
                        <Form.Check.Input
                            type='checkbox'
                            onChange={ (event) => {
                                if (event.target.checked) {setIsPrivate(true)}
                                else {setIsPrivate(false)}
                                }
                            }
                            name={`${formName}[private]`}
                            defaultChecked={isPrivate}
                        />
                        <Form.Check.Label> <span>Cochez s'il s'agit d'un webinaire à public désigné</span> <i className={isPrivate ? "fas fa-lock" : "fas fa-lock-open"}> </i> </Form.Check.Label>
                </Form.Check>

				<Row className="m-2">
					{ isPrivate && <>
						<Form.Label><h3>Clients cibles du webinaire</h3></Form.Label>
						<SelectMultipleClientsComponent
							nameSelect={`${formName}[clients][]`}
							selectedClients = {selectedClients?.length ? selectedClients:[]}
							setSelectedClients = {setSelectedClients} 
							/>
						</>
                    }
				</Row>
				<div className="mb-4">	
					<SelectMultipleItemsWithSearchBar idSelector={`${formName}[pedagogicModules][]`} label="Module(s) pédagogique(s) à associer"
						allItems={allPedagogicModules} 
						selectedItemKeys={selectedPedagogicModules} setSelectedItemKeys={setSelectedPedagogicModules} 
						itemKey="uniqueId" itemValue="name" />
				</div>

		        <Form.Group >
			        <Form.Control
				        name={`${formName}[_token]`}
				        value={csrfToken}
				        type="hidden"
			        />
		        </Form.Group>

					<ButtonLoaderComponent
						onClick={checkData} 
						isSending={isSending}
						disabled={isSending}
						msg={formationToUpdate?.uniqueId ? <><i className="fas fa-save"/> &nbsp;Éditer</>:<><i className="fas fa-save"/> &nbsp;Sauvegarder</>}
					/>

	        </Form>
        </div>
    )
}

export default FormationFormComponent;