import React from "react";
import { useNavigate } from 'react-router-dom';
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import HelperMetiers360 from "../../../../services/HelpersMetiers360.js";
import DynamicTable from "../../../widgets/dynamicTable/DynamicTable.jsx";
import TooltipListComponent from "../../../widgets/tooltipList/TooltipListComponent.jsx";
import './SequenceTableComponent.scss';
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState.jsx";

/**
 * 
 * @param {Array} sequences array of all sequences to be rendered
 * @param {String} readAll  readAll slug to access the video list page
 * @param {String} readOne  readOne slug to access a video details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the video creation page - optional, need according rights
 * @param {String} update  update slug to access the video update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 
 * @example
 *  <SequenceTableComponent sequences={sequences} readAll={readAllSlug} create={createSlug} {...}/>
 * 
 */

const SequenceTableComponent = (props) => {
    const { sequences, readOne: readOneSequenceSlug, withParams} = props;

	const { allStories, isFetchingStories } = useCustomGetStoreState("stories")
    const navigate = useNavigate();
    const sequencesToDisplay = sequences ? sequences : [];

   
    const listStories = (sequence) => {

            return sequence.stories.reduce((acc, storyUniqueId) => {
                const thisStory = allStories.find((story) => story.uniqueId === storyUniqueId);
                if (thisStory) {
                     acc.push({
                        uniqueId: thisStory.uniqueId,
                        name: thisStory.name
                    })
                }
                return acc;
            }, []);

        };
    
    const producersName = (producers) => producers.sort((a, b) => a.clientName - b.clientName).map((producer) => producer.clientName ).map(String)?.join(', ');
   
    if(sequences.length === 0 )
    {return <></>;}

    const sortType = [
        {
            value : 'thumbnail', label : 'Vignette',
            test : sequencesToDisplay[0].links.thumbnail !== undefined,
            method: null,
            display: (sequence) => <OverlayTrigger
                placement='right'
                overlay={
                <Tooltip>
                    <Image src={sequence.links.thumbnail[128]} />
                </Tooltip>
                }
            >
                <Image src={sequence.links.thumbnail[512]} className="img-overlay" thumbnail />
            </OverlayTrigger>,
            flatDisplay: (sequence) => sequence.links.thumbnail[512]
        },
        {
            value : 'name', label : 'Nom',
            test : sequencesToDisplay[0].name !== undefined,
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (sequence) => sequence.name
        },
        {
            value : 'stories', label : 'Stories',
            test : sequencesToDisplay[0].stories !== undefined,
            method: (a,b) =>  b.stories.length - a.stories.length,
            display: (sequence) => {
                const storyList = listStories(sequence);
                if (!storyList || !storyList.length)
                    return 0
                
                const generateIdCallback = (uniqueId) => {
                    return `story-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={storyList}
                    keyToDisplay='name'
                    generateIdCallback={generateIdCallback}
                    isFetching={isFetchingStories}
                 />
            },
            flatDisplay: (sequence) => listStories(sequence).length
        },
        {
            value: 'position', label: "Position",
            test : sequencesToDisplay[0].position !== undefined,
            method: (a, b) => b.position - a.position,
            display: (sequence) => sequence.position
        },
        {
            value: 'releaseDate', label: "Publication",
            test : sequencesToDisplay[0].releaseDate !== undefined,
            method: (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate),
            display: (sequence) => HelperMetiers360.getdisplayDateType(sequence.releaseDate, 'day')
        },
        {
            value: 'lastUpdated', label: "Mise à jour",
            test : sequencesToDisplay[0].lastUpdated !== undefined,
            method: (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated),
            display: (sequence) => HelperMetiers360.getdisplayDateType(sequence.lastUpdated, 'day')
        },
        {
            value: 'producers', label: "Producteur",
            test : sequencesToDisplay[0].producers !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(producersName(a.producers), producersName(b.producers)),
            display: (sequence) => sequence.producers.length ? producersName(sequence.producers) : '-'
        },
        {
            value: 'featured', label: "Mise en avant",
            test : sequencesToDisplay[0].featured !== undefined,
            method: (a, b) => {const aFeatured = a.featured ? 'X':'-'; return aFeatured.localeCompare(b.featured ? 'X':'-');},
            display: (sequence) => sequence.featured ? 'X' : '-'
        },
        {
            value: 'private', label: "Privée",
            test : sequencesToDisplay[0].private !== undefined,
            method: (a, b) => {const aPrivate = a.private ? 'X':'-'; return aPrivate.localeCompare(b.private ? 'X':'-');},
            display: (sequence) => sequence.private ? 'X' : '-'
        },
        {
            value: 'freemium', label: "Free",
            test : sequencesToDisplay[0].freemium !== undefined,
            method: (a, b) => {const aFreemium = a.freemium ? 'X':'-'; return aFreemium.localeCompare(b.freemium ? 'X':'-');},
            display: (sequence) => sequence.freemium ? 'X' : '-'
        },
        {
            value: 'view', label: "Vues",
            test : sequencesToDisplay[0].viewCount !== undefined,
            method: (a, b) => b.viewCount - a.viewCount,
            display: (sequence) => sequence.viewCount ? sequence.viewCount:0
        },
        
    ];

    const handleClick = (uniqueId) => {
        navigate(readOneSequenceSlug.replace(':uniqueId', uniqueId));
    }

    return <DynamicTable 
        contentTable = {sequencesToDisplay}
        contentSort = {sortType}
        valueInitSort = "name"
        index = 'uniqueId'
        handleClick={handleClick}
        withParams={withParams}
        tableName="sequencesTable"
        filename="parcours"
        />;
}

export default SequenceTableComponent;