import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Table } from 'react-bootstrap';
import useDynamicTableWithParams from "../../../hooks/useDynamicTableWithParams.jsx"
import CsvDownloadComponent from '../csvDownload/CsvDownloadComponent.jsx';

import './DynamicTable.scss';
import { useStoreActions, useStoreState } from 'easy-peasy';

const TdLinkWrapper = ({getUrl, content, children, ...rest}) => {
    const pathname = getUrl ? getUrl(content) : false;

    if (pathname) {
        return <td className='align-middle' style={{cursor: 'pointer'}} {...rest}><a href={pathname}>{children}</a> </td>
    }
    return <td className='align-middle' {...rest}> {children} </td>;
}

TdLinkWrapper.propTypes = {
    getUrl: PropTypes.string,
    content: PropTypes.object,
    children: PropTypes.any
};

/**
 * DynamicTable widget
 *
 * @version 1.0.0
 * @param {array} contentTable the data to display
 * @param {array} contentSort contains all necessary data for rendering :
 *      value : a slug for seleting data
 *      label : label for coulumn heading
 *      test : test of existence of the data
 *      method : method of sorting the column
 *      display : how to render the data
 * @param {string} index name for indexing data
 * @param {function} handleClick callback function to handle click on a particular row
 */

const DynamicTable = ({
    contentTable,
    contentSort,
    index,
    handleClick,
    valueInitSort,
    className,
    activeRowId,
    withParams,
    downloadable = true,
    withCheckBoxes = false,
    lineIndexesChecked = null,
    setLineIndexesChecked = null,
    enableToControlVisibleColumns = true,
    tableName = null,
    ...other
}) => {
    const noSortCallback = useCallback(() => 1, []);
    const [sort, setSort] = useState(valueInitSort ? contentSort?.find(c => c.value === valueInitSort) : {method: noSortCallback});
    const [revert, setRevert] = useState(false);
    const [isOptionsTableOpen, setIsOptionsTableOpen] = useState(false);
    const tablesColumnsVisiblePreference = useStoreState(state => state.preferences.tablesColumnsVisible);
    const {updateTableColumnsVisible, addTableColumnsVisible} = useStoreActions(actions => actions.preferences);
    useDynamicTableWithParams({withParams, sort, setSort, revert, setRevert, contentSort, initSort: valueInitSort ? contentSort?.find(c => c.value === valueInitSort) : {method: noSortCallback}
})

    const [downloadCSV, setDownloadCSV] = useState(false);

    useEffect(() => {
        enableToControlVisibleColumns && tablesColumnsVisiblePreference[tableName] === undefined
            && addTableColumnsVisible({
                tableName,
                tableColumnsVisible: Object.fromEntries(contentSort
                    .filter(content => content.test)
                    .map(({value, test}) => [value, true])
                )
            })
    }, []);

    useEffect(() => {
        if(tablesColumnsVisiblePreference[tableName]) {
            const columnPreferences = Object.keys(tablesColumnsVisiblePreference[tableName]);
            contentSort.filter(content => content.test)
                .map(content => content.value)
                .forEach(column => {
                    if(!columnPreferences.includes(column)) {
                        updateTableColumnsVisible({
                            tableName,
                            tableColumnsVisible: {[column]: true}
                        })
                    }
                })
        }
    }, [contentSort])

    const onChoiceSort = (choice) => {
        const targetSort = contentSort.filter(type => type.value === choice)[0];
        let newVal;
        if(sort.value === targetSort.value) {
            newVal = !revert;

            setRevert(newVal);
            
        }
        else {
            setSort(targetSort);

        }
    };

    const checkboxChange = (event, itemIndex) => {
        const newCheckboxesChecked = event.target.checked
            ? [...lineIndexesChecked, itemIndex]
            : lineIndexesChecked?.filter(itemIndexSelect => itemIndexSelect !== itemIndex);
        setLineIndexesChecked(newCheckboxesChecked);
    }

    const changeAllCheckboxes = (event) => {
        if(event.target.checked) {
            setLineIndexesChecked(contentTable.map(item => item[index]));
        } else {
            setLineIndexesChecked([]);
        }
        [...document.getElementsByClassName('checkbox-table-dynamic')]
            .forEach(el => el.checked = event.target.checked);
    }

    const handleClickForMultiple = (index) => {
        if(withCheckBoxes) {
            const indexAlreadyChecked = lineIndexesChecked?.some(lineIndex => lineIndex == index);
            if(indexAlreadyChecked) {
                setLineIndexesChecked([...lineIndexesChecked?.filter(lineIndex => lineIndex != index)]);
            } else {
                setLineIndexesChecked([...lineIndexesChecked, index]);
            }
            document.getElementById('checkbox-table-dynamic-'+index).checked = !indexAlreadyChecked;
        }
    }

    let compt = 0;

    const tableRows = useMemo(() => contentTable
        .sort(sort.method)
        .map((content) => {
            compt++;
            return <tr 
                key={content[index]}  
                style={content?.backgroundColor ? { backgroundColor: content?.backgroundColor } : null}
                className={(activeRowId && activeRowId === content[index] ? 'active ' : '')
                    + (handleClick ? 'clickable' : null)}
                onClick={handleClick ? () => handleClick(content[index]) : () => handleClickForMultiple(content[index])}
                >
                    { withCheckBoxes && 
                        <td className='align-middle'>
                            <input type="checkbox" defaultChecked={lineIndexesChecked?.includes(content[index])}
                                id={'checkbox-table-dynamic-'+content[index]}
                                className='checkbox-table-dynamic'
                                onChange={(e) => checkboxChange(e, content[index])}/>
                        </td>
                    }
                    <td className='align-middle' >{compt}</td>
                    {contentSort
                        .map(type => type.test 
                            && (!enableToControlVisibleColumns || (tablesColumnsVisiblePreference[tableName]?.[type.value]))
                            && <TdLinkWrapper key={type.value.concat('-',content[index])}  content={content} getUrl={type.getRedirectUrl}>{type.display(content)}</TdLinkWrapper>
                        )
                    }
            </tr>
        })
    , [tablesColumnsVisiblePreference, contentSort]);

    if(revert) {tableRows.reverse()}

    const table = useMemo(() => <Table striped bordered hover size="sm" className={className ?? 'table-activity'}>
            <thead id="table-header" className='text-center'>
                <tr>
                    { withCheckBoxes && 
                        <th className='bg-white'>
                            <input type="checkbox" defaultChecked={lineIndexesChecked?.length > 0}
                                onChange={(e) => changeAllCheckboxes(e)}/>
                        </th>
                    }
                    <th className='bg-white'>#</th>
                    {contentSort.map(type => type.test 
                        && (!enableToControlVisibleColumns || (tablesColumnsVisiblePreference[tableName]?.[type.value]))
                        && <th
                            key={type.value}
                            style={type.method ? { cursor: 'pointer' }:null}
                            onClick={type.method ? () => onChoiceSort(type.value):null}
                            className={sort.value === type.value ? 'bg-primary text-white' : 'bg-white'}>
                            {type.label}
                        </th>
                    )}
                </tr>
            </thead>
            <tbody className='text-center'>
                {tableRows}
            </tbody>
        </Table>
    , [tablesColumnsVisiblePreference, contentSort, sort, revert]);

    const resetTableColumnsVisible = () => {
        let newTablesColumnsVisiblePreference = tablesColumnsVisiblePreference[tableName];

        Object.keys(newTablesColumnsVisiblePreference).forEach(function(key) {
            newTablesColumnsVisiblePreference[key] = true;
        });

        updateTableColumnsVisible({
            tableName, 
            tableColumnsVisible: newTablesColumnsVisiblePreference
        });

        document.getElementById('check-columns-visible')
            .querySelectorAll('input[type=checkbox]')
            .forEach(el => {el.checked = true});
    }

    const visibleColumnsUncheckedCount = useMemo(() => 
        tablesColumnsVisiblePreference[tableName]
            ? Object.values(tablesColumnsVisiblePreference[tableName])?.reduce((acc, current) => { 
                return !current ? acc + 1 : acc; 
            }, 0)
            : 0
    , [tablesColumnsVisiblePreference]);

    const optionsTableButton = enableToControlVisibleColumns
        && <Button variant="none" 
                className={'btn-options-table ' + (isOptionsTableOpen ? 'btn-options-table-open' : '')} 
                onClick={() => setIsOptionsTableOpen(!isOptionsTableOpen)}>
            Options du tableau
            { visibleColumnsUncheckedCount > 0 && " (" + visibleColumnsUncheckedCount + ")" }
            { isOptionsTableOpen 
                ? <i className="fa fa-caret-up ms-3"></i>
                : <i className="fa fa-caret-down ms-3"></i>
            }
        </Button>;

    const optionsTableContent = isOptionsTableOpen && tablesColumnsVisiblePreference[tableName]
        && <div className='options-table-content d-flex flex-wrap' id='check-columns-visible'>
            <b className='me-3'>Colonnes à afficher :</b>
            { contentSort.map(type => type.test 
                && <Form.Check
                    type="checkbox"
                    className="me-3"
                    onChange={(e) => updateTableColumnsVisible({
                        tableName, 
                        tableColumnsVisible: {...tablesColumnsVisiblePreference[tableName], [type.value]: e.target.checked }}
                    )}
                    defaultChecked={tablesColumnsVisiblePreference[tableName][type.value]}
                    label={type.label}
                    key={type.value}
                    id={type.value}
                />)
            }
            { visibleColumnsUncheckedCount > 0 
                && <Button className="btn-link" size="sm" onClick={() => resetTableColumnsVisible()}>Afficher toutes les colonnes</Button>
            }
        </div>;

    return <>
        <div className={'d-flex mt-3 w-100' + (downloadable ? ' justify-content-between' : ' justify-content-end')}>
        { downloadable ?
                downloadCSV ?
                    <CsvDownloadComponent contentTable={contentTable} contentSort={contentSort} setDownloadCSV={setDownloadCSV} {...other} />
                    : <div onClick={()=>setDownloadCSV(true)} type="button" role="button"><i className="fas fa-download mr-2"/><span className='text-decoration-underline'>Télécharger en csv</span></div>
                :  null}
            { optionsTableButton }
            <span>{ contentTable.length } élément{ contentTable.length > 1 && 's'}</span>
        </div>
        { optionsTableContent }
        { table }
    </>;
}


DynamicTable.propTypes = {
    contentTable: PropTypes.array.isRequired,
    contentSort: PropTypes.array.isRequired,
    index: PropTypes.string.isRequired,
    handleClick: PropTypes.any,
    valueInitSort: PropTypes.string,
    className: PropTypes.string,
    activeRowId: PropTypes.string,
    withParams: PropTypes.bool,
    downloadable: PropTypes.bool,
    withCheckBoxes: PropTypes.bool,
    lineIndexesChecked: PropTypes.array,
    setLineIndexesChecked: PropTypes.any,
    enableToControlVisibleColumns: PropTypes.bool,
    tableName: PropTypes.string
};

export default DynamicTable;