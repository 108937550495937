import React from "react";
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Image, OverlayTrigger, Tooltip} from "react-bootstrap";

import HelperMetiers360 from "../../../../services/HelpersMetiers360.js";

import DynamicTable from "../../../widgets/dynamicTable/DynamicTable.jsx";
import DownloadFileComponent from '../../../widgets/DownloadFile/DownloadFileComponent.jsx';

const FormationTableComponent = ({ formations, withParams}) => {

    const formationsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('formations'));
    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfTool: allTools } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfLevel: allLevels } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;

    const navigate = useNavigate();

    const formationsToDisplay = formations ? formations : [];

   
  

    const getCategories = (formation) => {
        return allCategories.filter(category => formation.categories.includes(category.value))
        .map(category => category.label)
        ?.join(', ')
    };


    const getLevels = (formation) => {
        return allLevels.filter(level => formation.levels.includes(level.value))
        .map(level => level.label)
        ?.join(', ')
    };

    const getTools = (formation) => {
        return allTools.filter(tool => formation.tools.includes(tool.value))
        .map(tool => tool.label)
        ?.join(', ')
    };


    if (formations?.length === 0) { return <></>; }

    const sortType = [
        {
            value: 'image', label: 'Vignette',
            test: formationsToDisplay[0]?.links.image !== undefined,
            method: null,
            display: (formation) => (
                <OverlayTrigger
                    placement='right'
                    overlay={
                        <Tooltip>
                            <Image src={formation.links.image} />
                        </Tooltip>
                    }
                >
                    <Image src={formation.links.image} className="img-overlay" thumbnail />
                </OverlayTrigger>
            ),
            flatDisplay: (formation) => formation.links.image
        },
        {
            value: 'name', label: 'Nom',
            test: formationsToDisplay[0]?.name !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (formation) => formation.name
        },
        {
            value: 'sessions', label: 'Sessions',
            test: formationsToDisplay[0]?.sessionsCount !== undefined,
            method: (a, b) => a?.sessionsCount - b?.sessionsCount,
            display: (formation) => formation.sessionsCount == 0 ? "-" : formation.sessionsCount
        },
        {
            value: 'categories', label: 'Categories',
            test: formationsToDisplay[0]?.categories !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(getCategories(a), getCategories(b)),
            display: (formation) => getCategories(formation)  ? getCategories(formation) : "-"
        },
        {
            value: 'tools', label: 'Outils',
            test: formationsToDisplay[0]?.tools !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(getTools(a), getTools(b)),
            display: (formation) => getTools(formation)  ? getTools(formation) : "-"
        },
        {
            value: 'levels', label: 'Niveaux',
            test: formationsToDisplay[0]?.levels !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(getLevels(a), getLevels(b)),
            display: (formation) => getLevels(formation)  ? getLevels(formation) : "-"
        },
        {
            value: 'pedagogicModules', label: 'Modules pédagogiques',
            test:  formationsToDisplay[0]?.pedagogicModulesCount !== undefined,
            method: (a, b) => a?.pedagogicModulesCount - b?.pedagogicModulesCount,
            display: (formation) => formation.pedagogicModulesCount == 0 ? "-" : formation.pedagogicModulesCount
        },
        {
            value: 'clients', label: 'Public désigné',
            test:  formationsToDisplay[0]?.clientsCount !== undefined,
            method: (a, b) => a?.clientsCount - b?.clientsCount,
            display: (formation) => formation.clientsCount == 0 ? "-" : formation.clientsCount
        },
        {
            value: 'pdfFile', label: 'PDF',
            test: formationsToDisplay[0]?.pdfFile !== undefined,
            method: (a, b) => a?.pdfFile - b?.pdfFile,
            display: (formation) => formation.pdfFile == null ? 
                '-' 
                : <DownloadFileComponent as="a" bsPrefix={'no-btn'} path={formation.pdfFile} message={"Voir le pdf"}/> ,
            flatDisplay: (formation) => formation.pdfFile == null ? '-':formation.pdfFile
        },
        {
            value: 'releaseDate', label: 'Publication',
            test: formationsToDisplay[0]?.releaseDate !== undefined,
            method: (a, b) => new Date(b?.releaseDate) - new Date(a?.releaseDate),
            display: (formation) => formation.releaseDate == null ? 
            '-' 
            : HelperMetiers360.getdisplayDateType(formation.releaseDate, 'day')
        }
    ];

  

    const handleClick = (uniqueId) => {
        if(formationsSlugs.readOne) {
            navigate(formationsSlugs.readOne.replace(':uniqueId', uniqueId));
        }
    }

    return formationsToDisplay.length > 0
        && <DynamicTable
            contentTable={formationsToDisplay}
            contentSort={sortType}
            valueInitSort="name"
            index='uniqueId'
            handleClick={handleClick}
        withParams={withParams}
            tableName="formationsTable"
            filename="webinaires" />;
}
export default FormationTableComponent;