import React, {useState, useEffect } from 'react';
import { Container, Row, Col, ToggleButton, ToggleButtonGroup, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';

import HelperMetiers360 from '../../services/HelpersMetiers360.js';

import Loader from '../../components/widgets/Loader.jsx';
import StoryTableComponent from '../../components/stories/storyList/storyTable/StoryTableComponent.jsx';
import StoryBoardComponent from '../../components/stories/storyList/storyBoard/StoryBoardComponent.jsx';

import useCustomGetStoreState from '../../hooks/useCustomGetStoreState.jsx';
import useSearchWithParams from '../../hooks/useSearchWithParams.jsx';
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";

import "./StoriesPage.scss";

const StoryTableWithParams = withURLParamsTableComponent(StoryTableComponent);

const StoriesPage = () => {
	const { allStories, isFetchingStories } = useCustomGetStoreState("stories");

    const storySlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("stories"));

    const viewStylePreference = useStoreState(state => state.preferences.storyListStyle);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setStoryListStyle);

    const storyBoardSortType = useStoreState(state => state.preferences.storyBoardSortType);
    const setStoryBoardSortType = useStoreActions(actions => actions.preferences.setStoryBoardSortType);

    const [filteredStories, setFilteredStories] = useState(allStories);

    const {searchInput, search} = useSearchWithParams({
        placeholder: 'Rechercher par mots-clés (nom, description, média...)', delay: 400, width: "35vw"
    })
    
    const viewStyleType=[
        {value : 'table', label:<><i className="fas fa-table"></i></>, view : <StoryTableWithParams stories = {filteredStories} {...storySlugs} />},
        {value : 'cards', label:<><i className="fas fa-address-card"></i></>, view : <StoryBoardComponent stories = {filteredStories} sortTypePreferenceTuple={[storyBoardSortType, setStoryBoardSortType]} {...storySlugs} />},
    ];

    const labelVR='VR360';

    const [viewStyle, setViewStyle] = useState(viewStylePreference);

    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value === viewStyle}>{type.label}</ToggleButton>
    );

    const createStoryButton = storySlugs.create
    ? <Button variant="success" as={Link} to={storySlugs.create}><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>
    : null;

	useEffect(() => {
        setFilteredStories(allStories)
	}, [allStories]);

    useEffect(() => {
        setFilteredStories(
           [ ...allStories.filter(story => 
                HelperMetiers360.isSearchInText(search, story.name)
                    || HelperMetiers360.isSearchInText(search, story.description)
                    || HelperMetiers360.isSearchInText(search, story.romes?.join())
                    || HelperMetiers360.isSearchInText(search, story.type)
                    || HelperMetiers360.isSearchInText(search, story.fromVideo)
                    || HelperMetiers360.isSearchInText(search, story.typeOfStory?.label)
                    || HelperMetiers360.isSearchInText(search, story.producers?.map((producer) => producer.clientName)?.join())
                    || (story.is360 && HelperMetiers360.isSearchInText(search, labelVR))
            )]
        );
	}, [search, allStories]);

    const isNoStories = !isFetchingStories && !allStories.length
    const isNoResult = !isFetchingStories && allStories.length && !filteredStories.length;

    return <>
        <Container fluid>
            <Row className="item-page">
                <Col className="m360-col3-start">
                    {searchInput}
                </Col>
                <Col className="m360-col3-center">
                    <ToggleButtonGroup type="radio" name="options" value={viewStyle} onChange={handleChange}>
                        {checkViewStyle}
                    </ToggleButtonGroup>
                </Col>
                <Col className="m360-col3-end">
                    {createStoryButton}
                </Col>
            </Row>
            <Row>
                { (isFetchingStories) && <Loader /> }
                { filteredStories.length > 0 && viewStyleType.filter(type => type.value===viewStyle)[0].view  }
                {isNoResult && <Alert className="mt-3" variant="warning">Aucun résultat</Alert>}
                {isNoStories && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucune story</Alert>}

            </Row>
        </Container>
        
    </>;
}

export default StoriesPage;