import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import  { Alert, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import HelperMetiers360 from '../../../services/HelpersMetiers360';
import useDebounceSearch from '../../../hooks/useDebounchSearch';
import DynamicTable from '../../widgets/dynamicTable/DynamicTable';
import AuthorizationChecker from '../../../services/AuthorizationChecker';

const HeadsetAccessibleVideos = ({ uploadedAndAccessibleVideos, withParams }) => {

    const [filteredVideos, setFilteredVideos] = useState(uploadedAndAccessibleVideos);
    const { searchInput, search } = useDebounceSearch({ placeholder: "Rechercher une expérience immersive" });

    useEffect(() => {
        setFilteredVideos(uploadedAndAccessibleVideos.filter(video => 
            HelperMetiers360.isSearchInText(search, video.name)
        ));
    }, [search])

    const displayThumbnail = (video) => 
        <Image src={video.thumbnail} className="img-overlay" thumbnail />;

    const displayUploadedVideo = (video) => 
        video.uploaded 
            ? <>Oui</> 
            : <OverlayTrigger placement="left" 
                overlay={<Tooltip>
                    Veuillez mettre à jour votre casque via le Loader pour charger cette expérience immersive.
                </Tooltip>}>
                <span>
                    Non<i className="fas fa-info-circle fa-lg ms-2"></i>
                </span>
            </OverlayTrigger>;

    const displayVideoSize = (video) => {
        if(video.videoSize) {
            return video.videoSizeInHeadset === video.videoSize
                ? <>100 %</>
                : <OverlayTrigger placement="left" 
                    overlay={<Tooltip>Le téléchargement de cette expérience immersive a rencontré un problème. 
                        Veuillez mettre à jour votre casque via le Loader pour finaliser le téléchargement.
                    </Tooltip>}>
                    <span>
                        <i className="fas fa-exclamation-triangle text-danger me-2"></i>
                        { video.videoSizeInHeadset
                            ? ((video.videoSizeInHeadset*100/video.videoSize).toFixed(0)).toString() + " %"
                            : "0 %"
                        }
                    </span>
                </OverlayTrigger>
        } else {
            return <>-</>;
        }
    }

    const sortVideoSize = (videoA, videoB) => {
        if((videoA.videoSize && !videoB.videoSize)
            || (videoA.videoSizeInHeadset !== videoA.videoSize
                && videoB.videoSizeInHeadset === videoB.videoSize)) {
            return -1;
        } else if((!videoA.videoSize && videoB.videoSize)
            || (videoA.videoSizeInHeadset === videoA.videoSize
                && videoB.videoSizeInHeadset !== videoB.videoSize)) {
            return 1;
        }
        return 0;
    }

    const sortType = [
        {
            value : 'thumbnail', label : 'Vignette',
            test : HelperMetiers360.isArrayContainsValue(filteredVideos, "thumbnail"),
            method: null,
            display: (video) => displayThumbnail(video),
            flatDisplay: (video) => video.thumbnail ?? '-'
        },
        {
            value : 'name', label : 'Nom',
            test : HelperMetiers360.isArrayContainsValue(filteredVideos, "name"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (video) => video.name
        },
        {
            value : 'accessible', label : 'Accessible',
            test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(filteredVideos, "accessible"),
            method: (a,b) => (a.accessible ? 'X':'-').localeCompare(b.accessible ? 'X':'-'),
            display: (video) => video.accessible ? 'Oui' : 'Non'
        },
        {
            value : 'uploaded', label : 'Chargée',
            test : HelperMetiers360.isArrayContainsValue(filteredVideos, "uploaded"),
            method: (a,b) => (a.uploaded ? 'X':'-').localeCompare(b.uploaded ? 'X':'-'),
            display: (video) => displayUploadedVideo(video),
            flatDisplay: (video) => video.uploaded ? 'Oui' : 'Non'
        },
        {
            value : 'size', label : 'Taille téléchargée',
            test : HelperMetiers360.isArrayContainsValue(filteredVideos, "videoSize"),
            method: (a,b) =>  sortVideoSize(a, b),
            display: (video) => displayVideoSize(video)
        },
    ];

    const backgroundColorVideoLine = (video) => 
        AuthorizationChecker.isAdmin() && !video.accessible && video.uploaded
            ? '#FFA7A9'
            : null;
    
    const tableFilteredWithAlertColors = filteredVideos.map(video => {
        return {
            ...video,
            backgroundColor: backgroundColorVideoLine(video)
        }
    })

    return uploadedAndAccessibleVideos?.length > 0
        ? <>
            <div className='w-100' style={{marginBottom: "-2em"}}>{searchInput}</div>
            { filteredVideos.length > 0
                ? <DynamicTable
                    contentTable={tableFilteredWithAlertColors}
                    contentSort={sortType}
                    valueInitSort="name"
                    index='uniqueId' 
                    filename="casque_experiences_immersives_chargees"
                    withParams={withParams} />
                : <Alert variant='warning' className='w-100'>Aucune expérience immersive ne correspond à votre recherche</Alert>
            }
        </>
        : <Alert variant='danger' className='w-100'>Pas d'expérience immersive disponible</Alert>;
}

HeadsetAccessibleVideos.propTypes = {
    uploadedAndAccessibleVideos: PropTypes.array.isRequired,
    withParams: PropTypes.bool
}

export default HeadsetAccessibleVideos;