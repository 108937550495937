import UploadVideoPage from '../pages/video/UploadVideoPage';
import UpdateVideoPage from '../pages/video/UpdateVideoPage';
import VideosPage from '../pages/video/VideosPage';
import VideoDetailPage from '../pages/video/VideoDetailPage';

import MyAccountDetailPage from '../pages/account/MyAccountDetailPage';

import WebappAccessesPage from '../pages/webapp/WebappAccessesPage';

import ClientsPage from '../pages/client/ClientsPage';
import ClientDetailPage from '../pages/client/ClientDetailPage';


import GroupsPage from '../pages/group/GroupsPage';
import GroupDetailPage from '../pages/group/GroupDetailPage';
import CreateGroupPage from '../pages/group/CreateGroupPage';
import UpdateGroupPage from '../pages/group/UpdateGroupPage';
import CatalogPage from '../pages/catalogPage/CatalogPage';


import SequencesPage from '../pages/sequence/SequencesPage';
import UpdateSequencePage from '../pages/sequence/UpdateSequencePage';
import UploadSequencePage from '../pages/sequence/UploadSequencePage';
import SequenceDetailPage from '../pages/sequence/SequenceDetailPage';

import UsersPage from '../pages/user/UsersPage'

import UploadStoryPage from '../pages/story/UploadStoryPage';
import UpdateStoryPage from '../pages/story/UpdateStoryPage'
import StoriesPage from '../pages/story/StoriesPage';
import StoryDetailPage from '../pages/story/StoryDetailPage';

import HeadsetsPage from '../pages/headsets/HeadsetsPage';

import QuizzesPage from '../pages/quiz/QuizzesPage';
import QuizDetailsPage from '../pages/quiz/QuizDetailsPage';
import UploadQuizPage from '../pages/quiz/UploadQuizPage';
import UpdateQuizPage from '../pages/quiz/UpdateQuizPage';

import PedagogicModulesPage from '../pages/pedagogicModule/PedagogicModulesPage';
import PedagogicModuleDetailPage from '../pages/pedagogicModule/PedagogicModuleDetailPage';
import UpdatePedagogicModulePage from '../pages/pedagogicModule/UpdatePedagogicModulePage';
import UploadPedagogicModulePage from '../pages/pedagogicModule/UploadPedagogicModulePage';

import TagsPage from '../pages/tag/TagsPage';
import UploadTagPage from '../pages/tag/UploadTagPage';


import TagOperationsPage from '../pages/tagOperation/TagOperationsPage';


import PlaylistsPage from '../pages/playlist/PlaylistsPage';
import PlaylistDetailPage from '../pages/playlist/PlaylistDetailPage';

import FaqPage from '../pages/faq/FaqPage';

import StatsPage from '../pages/stats/StatsPage';

import RomesPage from '../pages/rome/RomesPage';
import RomeDetailPage from '../pages/rome/RomeDetailPage';

import TransversalPropsPage from '../pages/rome/TransversalPropsPage';
import RomeTransversalPropDetailPage from '../pages/rome/RomeTransversalPropDetailPage';

import ClientsCRMPage from '../pages/client/ClientsCRMPage';
import FormationsPage from '../pages/formation/FormationsPage';
import FormationDetailPage from '../pages/formation/FormationDetailPage';
import FormationSessionsPage from '../pages/formationSession/FormationSessionsPage';
import UpdateFormationSessionPage from '../pages/formationSession/UpdateFormationSessionPage';
import UploadFormationPage from '../pages/formation/UploadFormationPage';
import UpdateFormationPage from '../pages/formation/UpdateFormationPage';

import InteractionsPage from '../pages/interactions/InteractionsPage';
import ClientStatusPage from '../pages/client/clientStatus/ClientStatusPage';

import OrdersPage from '../pages/order/OrdersPage';
import PreparatoryOrderDetail from '../pages/order/preparatoryOrder/PreparatoryOrderDetail';
import UpdatePreparatoryOrder from '../pages/order/preparatoryOrder/UpdatePreparatoryOrder';
import ReturnOrderDetail from '../pages/order/returnOrder/ReturnOrderDetail';
import CreateOrders from '../pages/order/CreateOrders';
import UpdateReturnOrder from '../pages/order/returnOrder/UpdateReturnOrder';
import HomeRomesPage from '../pages/rome/HomeRomesPage';

import WelcomeMessagePage from '../pages/welcomeMessage/WelcomeMessagePage';
import CreateWelcomeMessage from '../components/welcomeMessage/CreateWelcomeMessage';
import WelcomeMessageDetailPage from '../components/welcomeMessage/WelcomeMessageDetailPage';
import JobAppellationsPage from '../pages/rome/JobAppellationsPage';
import LabPage from '../pages/lab/LabPage';

const pagesComponents = {
	'videos': {
		'create': <UploadVideoPage />,
		'readAll': <VideosPage />,
		'readOne': <VideoDetailPage />,
		'update': <UpdateVideoPage />,
	},
	'groups': {
		'create': <CreateGroupPage />,
		'readAll': <GroupsPage />,
		'readOne': <GroupDetailPage />,
		'update': <UpdateGroupPage />,
	},
	'catalogue': {
		'index': <CatalogPage />,
	},
	'sequences': {
		'create': <UploadSequencePage />,
		'readAll':<SequencesPage />,
		'readOne': <SequenceDetailPage />,
		'update': <UpdateSequencePage />,
	},
	'pedagogicModules': {
		'create': <UploadPedagogicModulePage />,
		'readAll': <PedagogicModulesPage />,
		'readOne': <PedagogicModuleDetailPage />,
		'update': <UpdatePedagogicModulePage />,
	},
	'formations': {
		'create':  <UploadFormationPage />,
		'readAll': <FormationsPage />,
		'readOne': <FormationDetailPage />,
		'update':  <UpdateFormationPage />
	},
	'formationSessions': {
		'readOne': <FormationDetailPage />,
		'readAll': <FormationSessionsPage />,
		'update':  <UpdateFormationSessionPage />
	},
	'clients': {
		'create': null,
		'readAll': <ClientsPage />,
		'readOne': <ClientDetailPage />,
		'update': null,
	},
	'myAccount': {
		'index': <MyAccountDetailPage />,
	},
	'users': {
		'readAll': <UsersPage />
	},
	'headsets': {
		'readAll': <HeadsetsPage />
	},
	'webappAccesses': {
		'readAll': <WebappAccessesPage />
	},
	'stories': {
		'create': <UploadStoryPage />,
		'readAll': <StoriesPage />,
		'readOne': <StoryDetailPage />,
		'update': <UpdateStoryPage />,
	},
	'quizzes': {
		'readAll': <QuizzesPage />,
		'create': <UploadQuizPage />,
		'readOne': <QuizDetailsPage />,
		'update': <UpdateQuizPage />,
	},
	'tags': {
		'readAll':<TagsPage />,
		'create': <UploadTagPage />,
	},
	'tagOperations': {
		'readAll':<TagOperationsPage />,
	},
	'playlists': {
		'readAll': <PlaylistsPage />,
		'create': <>create playlists pages</>,//<UploadPlaylistsPage />,
		'readOne': <PlaylistDetailPage />,
		'update': <>update playlists pages</>,//<UpdatePlaylistsPage />,
	},
	'faq': {
		'index' : <FaqPage />
    },
	'stats': {
		'index' : <StatsPage />
	},
	'romes': {
		'readAll': <RomesPage />,
		'readOne': <RomeDetailPage />
	},
    'romesHome': {
		'index': <HomeRomesPage />
    },
	'clientsCRM': {
		'index' : <ClientsCRMPage />,
	},
	'orders': {
		'readAll' : <OrdersPage orderType='preparatoryOrders' />,
		'readOne' : <PreparatoryOrderDetail />,
		'create' : <CreateOrders orderType='preparatoryOrders' />,
		'update' : <UpdatePreparatoryOrder />
	},
	'returnOrders': {
		'readAll' : <OrdersPage orderType='returnOrders' />,
		'readOne' : <ReturnOrderDetail />,
		'create' : <CreateOrders orderType='returnOrders' />,
		'update' : <UpdateReturnOrder />
	},
	'areasOfInterest': {
		'readAll' : <TransversalPropsPage transversalProp='areasOfInterest' />,
		'readOne' : <RomeTransversalPropDetailPage transversalProp='areasOfInterest' />
	},
	'workingConditions': {
		'readAll' : <TransversalPropsPage transversalProp='workingConditions' />,
		'readOne' : <RomeTransversalPropDetailPage transversalProp='workingConditions' />
	},
	'skills': {
		'readAll' : <TransversalPropsPage transversalProp='skills' />,
		'readOne' : <RomeTransversalPropDetailPage transversalProp='skills' />
	},
	'jobAppellations': {
		'readAll' : <JobAppellationsPage />,
	},
	'interactions': {
		'index' : <InteractionsPage />
	},
	'clientStatus': {
		'readAll':<ClientStatusPage />,
	},
	'welcomeMessage': {
		'readAll' : <WelcomeMessagePage />,
		'readOne' : <WelcomeMessageDetailPage />,
		'create' : <CreateWelcomeMessage />,
		'update' : <CreateWelcomeMessage type="edit" />
	},
    'lab': {
		'index': <LabPage />
    },
};

export default pagesComponents