import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import { useStoreState } from "easy-peasy";
import VideoTableComponent from "../videos/videosList/VideosTableComponent.jsx";

const VideoTableWithParams = withURLParamsTableComponent(VideoTableComponent);

const VideoByTransversalPropComponent = (props) => {
    const { oneTransversalProp, uniqueId, transversalPropLabel } = props;

    const { isFetchingOneTransversalProp } = useCustomGetStoreState('romes');
    const { allVideos, isFetchingAllVideos } = useCustomGetStoreState("videos");
    const videoSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("videos"));

    const [proVideos, setProVideos] = useState([]);
    const { searchInput, search } = useSearchWithParams({ placeholder: 'Rechercher par mots-clés (nom, titre secondaire, description...)', delay: 400 });


    useEffect(() => {
        if (oneTransversalProp?.code === uniqueId && oneTransversalProp?.videosByPro) {
            setProVideos(allVideos?.filter(video => oneTransversalProp?.videosByPro?.includes(video.uniqueId))
                .sort((a, b) => oneTransversalProp?.videosByPro?.indexOf(b.uniqueId)
                    - oneTransversalProp?.videosByPro?.indexOf(a.uniqueId)))
        }
    }, [allVideos, oneTransversalProp, uniqueId]);

    const filteredVideos = useMemo(() => {
        if (proVideos) {
            if (!search) return proVideos;
            else {
                const filterVideos = (search) => {
                    if (search === '') {
                        return proVideos;
                    }
                    return proVideos.filter(video => HelperMetiers360.isSearchInText(search, video.videoName)
                        || HelperMetiers360.isSearchInText(search, video.subHeading)
                        || HelperMetiers360.isSearchInText(search, video.description)
                        || HelperMetiers360.isSearchInText(search, video.romes?.join())
                        || HelperMetiers360.isSearchInText(search, video.producers
                            ?.map(producer => producer.clientName)?.join())
                    );
                }
                return [...filterVideos(search)];
            }
        }
    }, [search, proVideos]);

    const isNoVideo = !isFetchingOneTransversalProp && !oneTransversalProp?.videosByPro?.length;

    return <>
        <Alert variant="info">Voici les expériences immersives associées à {transversalPropLabel} via les pros de l'expérience immersive.</Alert>
        <Row>
            <Col>
                {searchInput}
            </Col>
        </Row>
        <Row>
            <Col>
                {(isFetchingAllVideos || isFetchingOneTransversalProp) && <Loader />}
                {filteredVideos?.length > 0 && <VideoTableWithParams videos={filteredVideos} {...videoSlugs} />}
                {isNoVideo && <Alert className="mt-3" variant="warning">Aucun pro ne correspond à {transversalPropLabel}</Alert>}
                {!isNoVideo && filteredVideos?.length == 0 && <Alert className="mt-3" variant="warning">Aucune expérience immersive ne correspond à votre recherche "{search}"</Alert>}
            </Col>
        </Row>
    </>;
}

export default VideoByTransversalPropComponent;