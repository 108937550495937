import React, { useMemo } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";

import RomesTableComponent from "../../components/romes/RomesTableComponent.jsx";

import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";

import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import useBackButton from "../../hooks/useBackButton.jsx";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";

const RomesTableWithParams = withURLParamsTableComponent(RomesTableComponent);

const RomesPage = () => {

    const { allRomes, isFetchingAllRomes } = useCustomGetStoreState("romes")

    const { searchInput, search } = useSearchWithParams({ placeholder: 'Rechercher par mots-clés (nom, code ROME, riasec majeur...)', delay: 400 })
    const {backButtonComponent} = useBackButton({path: "/romesHome"});

    const filteredRomes = useMemo(() => {
        if(!search) return allRomes;
        else {
            const filterRomes = (search) => {
                if (search === '') {
                    return allRomes
                }
                return allRomes.filter(rome => HelperMetiers360.isSearchInText(search, rome.codeRome)
                    || HelperMetiers360.isSearchInText(search, rome.label)
                    || HelperMetiers360.isSearchInText(search, HelperMetiers360.riasecLabel(rome.riasecMajeur))
                    || HelperMetiers360.isSearchInText(search, HelperMetiers360.riasecLabel(rome.riasecMineur)))
            }
            return [...filterRomes(search)];
        }
    }, [search, allRomes]);

    return (
        <Container fluid>
            <Row className="item-page">
                <h2 className="d-flex justify-content-between">
                    Codes ROME
                    {AuthorizationChecker.isAdmin() && backButtonComponent}
                </h2>
                { !AuthorizationChecker.isAdmin() 
                    && <Alert variant="info">Retrouvez tous les métiers de notre catalogue, à travers leur fiche code ROME 
                        (<a target= "_blank" href="https://fr.wikipedia.org/wiki/R%C3%A9pertoire_op%C3%A9rationnel_des_m%C3%A9tiers_et_des_emplois">
                        Répertoire Opérationnel des Métiers et des Emplois</a>). Il vous suffit de cliquer sur un code ROME pour obtenir 
                        plus d’informations sur ce dernier, et afficher les ressources de Métiers360 correspondantes. Découvrez 
                        également à quelles personnalités <a target="_blank" href="https://fr.wikipedia.org/wiki/Mod%C3%A8le_RIASEC">RIASEC</a> est 
                        lié ce métier.
                    </Alert>
                }
            </Row>
            <Row>
                {searchInput}
            </Row>
            <Row>
                { isFetchingAllRomes 
                    ? <Loader />
                    : allRomes.length > 0
                        ? filteredRomes.length > 0 
                            ? <RomesTableWithParams romes={filteredRomes} />
                            : <Alert className="mt-3" variant="warning">Aucun métier ne correspond à votre recherche "{search}"</Alert>
                        : <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun métier</Alert>
                }
            </Row>
        </Container>
    )
}

export default RomesPage;