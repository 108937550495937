import React, { useEffect, useState, useRef } from "react";
import { Row, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import useSearchWithParams from "../../../hooks/useSearchWithParams.jsx";
import { useStoreActions, useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import SelectItemsComponent from '../../widgets/selectItemsComponent/SelectItemsComponent.jsx';
import './UsersFiltersComponent.scss';
import Loader from '../../widgets/Loader.jsx'
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";
import AuthorizationChecker from '../../../services/AuthorizationChecker.js';

import { useToast } from "../../../hooks/useToast.jsx";

const UsersFiltersComponent = ({ allUsers, setFilteredUsers, allParentClients, displayFilterZone = true }) => {
    const { searchInput, search } = useSearchWithParams({ placeholder: 'Rechercher par mots-clés (email, nom, prénom...)', delay: 400 });
    const [searchParam, setSearchParam] = useSearchParams();

    const toast = useToast();

    const { allTagOperations } = useStoreState(state => state.tagOperations);
    const { fetchTagOperations } = useStoreActions(actions => actions.tagOperations);
    const { clientsCategories } = useStoreState(state => state.clients);
    const { fetchClientsCategories } = useStoreActions(actions => actions.clients);
    const { allClientStatus } = useStoreState(state => state.clientStatus);
    const { fetchClientStatus } = useStoreActions(actions => actions.clientStatus);
    const [areFiltersApplied, setAreFiltersApplied] = useState(false);
    const [isFirstLoadFilters, setIsFirstLoadFilters] = useState(true);

    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [isApplyFilterNecessary, setIsApplyFilterNecessary] = useState(false);
    const [subscriptionFilter, setSubscriptionFilter] = useState('all');

    const parentClientSelectorRef = useRef(null);
    const tagOperationsSelectorRef = useRef(null);
    const categoriesSelectorRef = useRef(null);
    const statusSelectorRef = useRef(null);


    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {
            try {
                const clientsCategoriesPromise = !clientsCategories.length ? fetchClientsCategories() : Promise.resolve(null);
                const clientStatusPromise = !allClientStatus.length ? fetchClientStatus() : Promise.resolve(null);
                const tagOperationsPromise = !allTagOperations.lenght ? fetchTagOperations() : Promise.resolve(null);

                const [clientsCategoriesResult, tagOperationsResult, clientStatusResult] = await Promise.all([
                    clientsCategoriesPromise,
                    tagOperationsPromise,
                    clientStatusPromise,
                ]);

            } catch (error) {
                if (isMounted) {
                toast.open({ message: "Erreur lors de la récupération des filtres", variant: "danger" });
                }
            } finally {
                if (isMounted) {
                    setFiltersFromUrl();
                    setIsApplyFilterNecessary(true);
                    setIsFirstLoadFilters(false);
                }
            }
        };

        if (AuthorizationChecker.isAdmin()) {
            fetchData();
        } else {
            setFiltersFromUrl();
            setIsApplyFilterNecessary(true);
            setIsFirstLoadFilters(false);
        }

        return () => {
            isMounted = false;
        };

    }, []);

    const setFiltersFromUrl = () => {
        const newSearchParamTagOpe = searchParam.get('tagOpeSelected');
        const newSearchParamCategory = searchParam.get('categorySelected');
        const newSearchParamStatus = searchParam.get('statusSelected');
        const newSearchParamParent = searchParam.get('parentSelected');
        const newSearchParamSubscribers = searchParam.get('subscribers');

        if (newSearchParamTagOpe && tagOperationsSelectorRef?.current) {
            tagOperationsSelectorRef.current.value = newSearchParamTagOpe;
        }
        if (newSearchParamCategory && categoriesSelectorRef?.current) {
            categoriesSelectorRef.current.value = newSearchParamCategory;
        }
        if (newSearchParamStatus && statusSelectorRef?.current) {
            statusSelectorRef.current.value = newSearchParamStatus;
        }
        if (newSearchParamSubscribers) {
            setSubscriptionFilter(newSearchParamSubscribers)
        }
        if (newSearchParamParent && parentClientSelectorRef?.current) {
            parentClientSelectorRef.current.value = newSearchParamParent;
        }

        setIsFiltersOpen((newSearchParamTagOpe && newSearchParamTagOpe !== "") || (newSearchParamParent && newSearchParamParent !== "") 
            || (newSearchParamSubscribers && newSearchParamSubscribers !== "") || (newSearchParamCategory && newSearchParamCategory !== "")
            || (newSearchParamStatus && newSearchParamStatus !== ""));
    }

    const setUrlFromFilters = (subValue) => {
        const tagRef = tagOperationsSelectorRef.current
            ? tagOperationsSelectorRef.current.value ?? null
            : searchParam.get('tagOpeSelected');
        const parentRef = parentClientSelectorRef.current
            ? parentClientSelectorRef.current.value ?? null
            : searchParam.get('parentSelected');
        const catRef = categoriesSelectorRef.current
            ? categoriesSelectorRef.current.value ?? null
            : searchParam.get('categorySelected');
        const statusRef = statusSelectorRef.current
            ? statusSelectorRef.current.value ?? null
            : searchParam.get('statusSelected');
        const subFilter = subValue && typeof (subValue) === "string"
            ? subValue ?? null
            : searchParam.get('suscribers');

        setParamsToUrl([
            ['search', search ?? ""],
            ['tagOpeSelected', tagRef ?? ""],
            ['parentSelected', parentRef ?? ""],
            ['subscribers', subFilter ?? ""],
            ['categorySelected', catRef ?? ""],
            ['statusSelected', statusRef ?? ""],
        ])
    }

    const applyFiltersOnTable = () => {
        const tagOperationId = tagOperationsSelectorRef.current
            ? tagOperationsSelectorRef.current.value ?? null
            : searchParam.get('tagOpeSelected');
        const categoryId = categoriesSelectorRef.current
            ? categoriesSelectorRef.current.value ?? null
            : searchParam.get('categorySelected');
        const parentId = parentClientSelectorRef.current
            ? parentClientSelectorRef.current.value ?? null
            : searchParam.get('parentSelected');
        const subscriptionFilterRef = subscriptionFilter
            ?? searchParam.get('subscribers');
        const statusId = statusSelectorRef.current
            ? statusSelectorRef.current.value ?? null
            : searchParam.get('statusSelected');

        const usersToDisplay = allUsers
            ?.filter(user => {
                const parentCondition = !parentId ||  user.parentClientUniqueId === parentId 
                    || (parentId === 'aucun' && !user.parentClientUniqueId);
                const tagCondition = !tagOperationId
                    || user.tagOperations?.some(tag =>
                        tag.uniqueId == tagOperationId)
                    || (tagOperationId === 'aucun' && !user.tagOperations?.length);
                const catCondition = !categoryId
                    || user.categories?.some(cat =>
                        cat === clientsCategories.find(cat => cat.id == categoryId)?.name)
                    || (categoryId === 'aucun' && !user.categories?.length);
                const subCondition = subscriptionFilterRef === 'all' 
                    || (subscriptionFilterRef === 'inactive' && new Date (user.subscriptionEndDate) < new Date()) 
                    || (subscriptionFilterRef === 'active' && new Date (user.subscriptionEndDate) >= new Date());
                const statusCondition = !statusId
                    || user.status == allClientStatus?.find(status => status.id == statusId)?.label
                    || (statusId === 'aucun' && (!user.status || user.status == ""));

                return parentCondition && tagCondition && subCondition && catCondition && statusCondition;
            })
            ?.filter(user => 
                HelperMetiers360.isSearchInText(search, user.email)
                || HelperMetiers360.isSearchInText(search, user.firstName)
                || HelperMetiers360.isSearchInText(search, user.lastName)
                || HelperMetiers360.isSearchInText(search, user.clientName)
                || HelperMetiers360.isSearchInText(search, user.job)
                || HelperMetiers360.isSearchInText(search, user.tagOperations?.join())
                || HelperMetiers360.isSearchInText(search, user.categories?.join())
            );

        setFilteredUsers(usersToDisplay);

        setAreFiltersApplied(search || tagOperationId || parentId || subscriptionFilter !== 'all' || categoryId || statusId);
        setIsApplyFilterNecessary(false);
    }

    const setParamsToUrl = (newParams) => {
        const paramsToSet = {}
        for (const [key, value] of searchParam.entries()) {
            paramsToSet[key] = value;
        }
        for (const [key, value] of newParams) {
            paramsToSet[key] = value;
        }
        setSearchParam(paramsToSet);
    }

    const resetFilters = () => {
        setParamsToUrl([
            ['search', ""],
            ['subscribers', 'all'],
            ['tagOpeSelected', ""],
            ['parentSelected', ""],
            ['categorySelected', ""],
            ['statusSelected', ""],
        ]);
        setSubscriptionFilter('all');
        if (tagOperationsSelectorRef.current?.value) tagOperationsSelectorRef.current.value = "";
        if (parentClientSelectorRef.current?.value) parentClientSelectorRef.current.value = "";
        if (categoriesSelectorRef.current?.value) categoriesSelectorRef.current.value = "";
        if (statusSelectorRef.current?.value) statusSelectorRef.current.value = "";

        setIsApplyFilterNecessary(true);
    }

    useEffect(() => {
        isApplyFilterNecessary && applyFiltersOnTable();
    }, [isApplyFilterNecessary]);

    useEffect(() => {
        !isApplyFilterNecessary && setIsApplyFilterNecessary(true);
    }, [search, allUsers]);

    const onFilterChange = (subValue) => {
        setUrlFromFilters(subValue);
        setIsApplyFilterNecessary(true);
    }

    const changeSubscribers = (subValue) => {
        setSubscriptionFilter(subValue);
        onFilterChange(subValue);
    }

    return <>
        <Row className={"d-flex align-items-center pe-0"}>
            { displayFilterZone
                && <Col className="d-flex align-items-center">
                    <Button id="btnFilters" className="cursor-pointer shadow" 
                        onClick={() => setIsFiltersOpen(!isFiltersOpen)} onKeyDown={() => setIsFiltersOpen(!isFiltersOpen)}>
                        <p className="me-3">Filtres</p>
                        { isFiltersOpen 
                            ? <i className="fa fa-caret-up"></i>
                            : <i className="fa fa-caret-down"></i>
                        }
                    </Button>
                    { areFiltersApplied && 
                        <OverlayTrigger placement="top"
                            overlay={ <Tooltip>Effacer les filtres</Tooltip> }>
                            <Button id="btnInitFilters" className="shadow" variant="danger" size="sm" onClick={() => { resetFilters()} }>
                                <i className="fas fa-redo"></i>
                            </Button>
                        </OverlayTrigger>
                    }
                    { isFirstLoadFilters && 
                        <div id="loader-first-loading-filter"><Loader/></div>
                    }
                </Col>
            }
            <Col className="pe-0">
                {searchInput}
            </Col>
        </Row>
        {displayFilterZone
            && <div id="filtersZone" className={!isFiltersOpen ? "hide-filters" : undefined}> 
                <Row className="d-flex align-items-center justify-content-between mt-2">
                    <Col className="m360-col3-start" lg={12} xl={6} xxl={4}>
                        <div className="d-flex align-items-center">
                            <label className="label-select">Enfants&nbsp;de&nbsp;: </label>
                            <SelectItemsComponent selectRef={parentClientSelectorRef} onChange={onFilterChange}
                                items={allParentClients} itemKey="uniqueId" itemValue="clientName" maxWidth={"28em"} labelNone={"parent"}/>
                        </div>
                    </Col>
                    <Col className="m360-col3-center" lg={12} xl={6} xxl={4}>
                        <div className="d-flex align-items-center">
                            <label className="label-select">Opé M360&nbsp;: </label>
                            <SelectItemsComponent selectRef={tagOperationsSelectorRef} onChange={onFilterChange}
                                items={allTagOperations} itemKey="uniqueId" itemValue="name" maxWidth={"28em"} labelNone={"opération"} />
                        </div>
                    </Col>
                    <Col className="m360-col3-end" lg={12} xl={6} xxl={3}>
                        <div className="d-flex align-items-center">
                            <label className="label-select">Abonnements&nbsp;: </label>
                            <div className='choices-selector-inline'>
                                <div className={subscriptionFilter === 'inactive' ? 'selected' : null} 
                                    onClick={() => changeSubscribers('inactive')} onKeyDown={() => changeSubscribers('inactive')}>
                                    Non&nbsp;actif</div>
                                <div  className={subscriptionFilter === 'all' ? 'selected' : null}
                                    onClick={() => changeSubscribers('all')} onKeyDown={() => changeSubscribers('all')}>
                                    Tous</div>
                                <div className={subscriptionFilter === 'active' ? 'selected' : null}
                                    onClick={() => changeSubscribers('active')} onKeyDown={() => changeSubscribers('active')}>
                                    Actif</div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-between mt-2">
                    <Col className="m360-col3-start" lg={12} xl={6} xxl={4}>
                        <div className="d-flex align-items-center">
                            <label className="label-select">Catégorie&nbsp;: </label>
                            <SelectItemsComponent selectRef={categoriesSelectorRef} onChange={onFilterChange}
                                items={clientsCategories} itemKey="id" itemValue="name" maxWidth={"28em"} labelNone={"catégorie"} />
                        </div>
                    </Col>
                    <Col className="m360-col3-center" lg={12} xl={6} xxl={4}>
                        <div className="d-flex align-items-center">
                            <label className="label-select">Statuts&nbsp;: </label>
                            <SelectItemsComponent selectRef={statusSelectorRef} onChange={onFilterChange}
                                items={allClientStatus} itemKey="id" itemValue="label" maxWidth={"28em"} labelNone={"status"} />
                        </div>
                    </Col>
                    <Col className="m360-col3-end" lg={12} xl={6} xxl={3}></Col>
                </Row>
            </div>
        }
    </>;
}

UsersFiltersComponent.propTypes = {
    allUsers: PropTypes.array.isRequired,
    setFilteredUsers: PropTypes.any.isRequired,
    allParentClients: PropTypes.array.isRequired,
    displayFilterZone: PropTypes.bool
};

export default UsersFiltersComponent;