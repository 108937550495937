import React from "react";

import { Route, Routes } from "react-router-dom";
import {useStoreState, useStoreActions} from 'easy-peasy';


import "./Main.scss";
import useBreadCrumbs from "../../hooks/useBreadCrumbs";
import AuthorizationChecker from "../../services/AuthorizationChecker";

const Main = (props) => {
	const {isMobile, navIsOpen} = useStoreState(state => state.responsive);
	const {setNavIsOpen} = useStoreActions(actions => actions.responsive);
	const {pages} = useStoreState(state => state.actionSlugs);
	const {breadcrumbComponent} = useBreadCrumbs();
    const isAdmin = AuthorizationChecker.isAdmin();

    const errorPage = pages?.find((page) => page.id === 'error');

	return (
		<main id='main-wrapper'>
            {isAdmin && breadcrumbComponent}
			{isMobile && navIsOpen && <div  onClick={()=>(setNavIsOpen(false))} className='backdrop'></div>}
		
			{ pages && pages.length && <Routes>
				{
						pages
							.filter(pages => !pages.isSeparator )
							.map((page) => (
								<Route key={page.id} path={page.slug} element={page.content}></Route>
							))
				}
				<Route path="*" element={errorPage.content} />
			</Routes>}
		</main>
	);
};

export default Main;
