import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";

import HelperMetiers360 from "../../../services/HelpersMetiers360.js";
import useInfiniteLoadingLogic from "../../../hooks/useInfiniteLogic.jsx";
import TooltipListComponent from "../../widgets/tooltipList/TooltipListComponent.jsx";
import DynamicTableInfinite from "../../widgets/dynamicTableInfinite/DynamicTableInfinite.jsx";
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState.jsx";

import './VideosTableComponent.scss';
import AuthorizationChecker from "../../../services/AuthorizationChecker.js";
import PropTypes from 'prop-types';

/**
 * 
 * @param {Array} videos array of all videos to be rendered
 * @param {String} readAll  readAll slug to access the video list page
 * @param {String} readOne  readOne slug to access a video details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the video creation page - optional, need according rights
 * @param {String} update  update slug to access the video update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 
 * @example
 *  <VideoTableComponent videos={videos} readAll={readAllSlug} create={createSlug} {...}/>
 * 
 */

const VideoTableComponent = (props) => {
    const { videos, readOne: readOneVideoSlug, withParams } = props;

    const navigate = useNavigate();

    const [sortedVideos, setSortedVideos] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    useEffect(() => {
        setShouldSort(true);
        setSortedVideos([...videos]);
    }, [videos]);

    const [videosToDisplay, setVideosToDisplay] = useState([]);
    const {hasMore, fetchNext: fetchNextVideos} = useInfiniteLoadingLogic(sortedVideos, videosToDisplay, setVideosToDisplay, 40);

    const { allStories, isFetchingStories } = useCustomGetStoreState("stories")
    const { allPedagogicModules, isFetchingAllPedagogicModules} = useCustomGetStoreState("pedagogicModules");

    const codeRome = (romes) => romes?.join(', ');

    const listStories = (video) => {
        const stories = allStories.filter(story => (
            story.fromVideoUniqueId === video.uniqueId
        ));

        return stories;
    };

    const listPedagogicModules = (video) => {
        const pedagogicModules = allPedagogicModules
        .filter(pedagogicModule => ( pedagogicModule.videos
        .some(v => v.uniqueId === video.uniqueId)
        ));

        return pedagogicModules;
    };

    const producersName = (producers) => producers?.length ? producers.map((producer) => producer.clientName ).map(String)?.join(', '):null;

    const getListGroupSize = (video) => {
        return video.groups ? video.groups?.length:0;
    };
    
    if (videos?.length === 0)
        return <></>;

    const thumbnailDisplay = (video) => <OverlayTrigger
            placement='right'
            overlay={
            <Tooltip>
                <Image src={video.links.thumbnail} />
            </Tooltip>
            }
        >
            <Image src={video.links.thumbnail} className="img-overlay" thumbnail />
        </OverlayTrigger>;

    const groupsDisplay = (video) => {
        if (!video.groups) {
            return <span>0</span>
        }

        const generateIdCallback = (groupUniqueId) => {
            return `group-${groupUniqueId}${video.uniqueId}`
        }

        return <TooltipListComponent inputList={video.groups} keyToDisplay='name' generateIdCallback={generateIdCallback}/>
    }

    const pedagogicModulesDisplay = (video) => {
        const pedagogicModuleList = listPedagogicModules(video);
        if (pedagogicModuleList?.length === 0) {
            return <span>0</span>
        }

        const generateIdCallback = (pedagogicModuleUniqueId) => {
            return `pedagogicModule-${pedagogicModuleUniqueId}`
        }

        return <TooltipListComponent
            inputList={pedagogicModuleList}
            keyToDisplay='name'
            generateIdCallback={generateIdCallback} 
            isFetching={isFetchingAllPedagogicModules}/>
    }

    const storiesDisplay = (video) => {
        const storyList = listStories(video);
        if (storyList?.length === 0) {
            return <span>0</span>
        }

        const generateIdCallback = (storyUniqueId) => {
            return `story-${storyUniqueId}`
        }

        return <TooltipListComponent
            inputList={storyList}
            keyToDisplay='name'
            generateIdCallback={generateIdCallback} 
            isFetching={isFetchingStories}/>
    }

    // const groupsDisplay = (video) =>

    const sortType = !sortedVideos?.length ? [] : [
        {
            value : 'thumbnail', label : 'Vignette',
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "links") && sortedVideos[0].links.thumbnail !== undefined,
            method: null,
            display: (video) => thumbnailDisplay(video),
            flatDisplay: (video) => video?.links?.thumbnail
        },
        {
            value : 'name', label : 'Nom',
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "videoName"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.videoName, b.videoName),
            display: (video) => video.videoName
        },
        {
            value : 'subHeading', label : 'Titre secondaire',
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "subHeading"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.subHeading, b.subHeading),
            display: (video) => video.subHeading || '-'
        },
        {
            value: 'groups', label: 'Groupes',
            test: AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(sortedVideos, "groups") ,
            method: (a, b) => getListGroupSize(b) - getListGroupSize(a),
            display: (video) => groupsDisplay(video),
            flatDisplay: (video) => video?.groups?.length
        },
        {
            value : 'pedagogicModules', label : 'Modules',
            test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(sortedVideos, "pedagogicModules"),
            method: (a,b) => listPedagogicModules(b)?.length - listPedagogicModules(a)?.length,
            display: (video) => pedagogicModulesDisplay(video),
            flatDisplay: (video) => listPedagogicModules(video)?.length
        },
        {
            value : 'stories', label : 'Stories',
            test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(sortedVideos, "stories"),
            method: (a,b) => listStories(b)?.length - listStories(a)?.length,
            display: (video) => storiesDisplay(video),
            flatDisplay: (video) => listStories(video)?.length
        },
        {
            value: 'romes', label: "Codes Rome",
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "romes"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(codeRome(a.romes), codeRome(b.romes)),
            display: (video) => video.romes?.length ? codeRome(video.romes) : '-'
        },
        {
            value: 'pros', label: "Pros",
            test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(sortedVideos, "prosCount"),
            method: (a,b) => HelperMetiers360.compareWithNullable(a.prosCount, b.prosCount),
            display: (video) => video.prosCount ?? 0
        },
        {
            value: 'producers', label: "Producteurs",
            test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(sortedVideos, "producers"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(producersName(a.producers), producersName(b.producers)),
            display: (video) => video.producers?.length ? producersName(video.producers) : '-'
        },
        {
            value: 'releaseDate', label: "Publication",
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "releaseDate"),
            method: (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate),
            display: (video) => HelperMetiers360.getdisplayDateType(video.releaseDate, 'day')
        },
        {
            value: 'updatedAt', label: "Mise à jour",
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "updatedAt"),
            method: (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
            display: (video) => HelperMetiers360.getdisplayDateType(video.updatedAt, 'day')
        },
        {
            value: 'private', label: "Privée",
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "private"),
            method: (a, b) => {const aPrivate = a.private ? 'X':'-'; return aPrivate.localeCompare(b.private ? 'X':'-');},
            display: (video) => video.private ? 'X' : '-'
        },
        {
            value : 'subtitles', label : "Sous-titres",
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "subtitles"),
            method: (a, b) => a.subtitles?.length === 0 ? -1 : (b.subtitles?.length === 0 ? 1 : b.subtitles?.length - a.subtitles?.length) ,
            display: (video) => video?.subtitles?.length !== 0 ? 
                video.subtitles?.map(
                    (subtitle, i) => < div key={video.uniqueId+subtitle.lang} >
                    <a href={subtitle.url} target="_blank" rel="noreferrer">
                        {subtitle.lang}
                    </a>
                    {i !== video.subtitles?.length - 1 && ", "}
                    </div>)
                : '-',
            flatDisplay: (video) => video?.subtitles?.length
        },
        {
            value : 'size', label : "Taille",
            test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(sortedVideos, "size"),
            method: (a, b) => b.size - a.size ,
            display: (video) => HelperMetiers360.formatBytes(video.size, 0)
        },
        {
            value : 'duration', label : "durée",
            test : AuthorizationChecker.isAdmin() && HelperMetiers360.isArrayContainsValue(sortedVideos, "videoLength"),
            method: (a, b) => b.videoLength - a.videoLength ,
            display: (video) => video.videoLength !== null ? HelperMetiers360.formatDuration(video.videoLength, 's'):'-'
        },
        {
            value: 'position', label: "Position",
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "position"),
            method: (a, b) => b.position - a.position,
            display: (video) => video.position
        },
        {
            value: 'directAccessCode', label: "Code d'accès",
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "directAccessCode"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.isDirectlyAccessible ? a.directAccessCode : "", b.isDirectlyAccessible ? b.directAccessCode : ""),
            display: (video) => video.isDirectlyAccessible && video.directAccessCode
        },
        {
            value: 'view', label: <i className='fas fa-play' />,
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "viewCount"),
            method: (a, b) => b.viewCount - a.viewCount,
            display: (video) => video.viewCount ? video.viewCount:0
        },
        {
            value: 'headset', label: <i className='nav-icon icon-VR-M360' />,
            test : HelperMetiers360.isArrayContainsValue(sortedVideos, "headsetsCount"),
            method: (a, b) => b.headsetsCount - a.headsetsCount,
            display: (video) => video.headsetsCount ? video.headsetsCount:0
        }
    ];

    const handleClick = (uniqueId) => {
        if(readOneVideoSlug) navigate(readOneVideoSlug.replace(':uniqueId', uniqueId));
    }

    return videosToDisplay.length > 0
        && <DynamicTableInfinite 
            contentTable = {videosToDisplay}
            contentSort = {sortType}
            valueInitSort = "name"
            index = 'uniqueId'
            handleClick={handleClick}
            fetchData={fetchNextVideos}
            hasMore={hasMore}
            setSortedContent={setSortedVideos}
            sortedContent={sortedVideos}
            sortState={[shouldSort, setShouldSort]}
        withParams={withParams}
            tableName="videosTable"
            filename="videos" />;
}

VideoTableComponent.propTypes = {
    videos : PropTypes.array.isRequired,
    readOne: PropTypes.string,
    withParams : PropTypes.bool,
};

export default VideoTableComponent;